import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import {
  createSocialSharingService,
  SocialSharePlatform,
} from '@/services/createSocialSharingService';
import { createStripePaymentService } from '@/services/createStripePaymentService';
import { ConversionReporting } from '@/tools/gtag/gtagReportConversion';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { displayLearnMoreSplash_ShareVersion } from './displayLearnMoreSplash';
import { createElementFadeInOutControl } from './utils/createElementFadeInOutControl';

import '@/debug/html/WIPDisclaimer';
import '@/html/common/GlowySpinnyDot';
import '@/html/common/HoverTooltip';
import '@/html/common/SocialIcon';

const TAG_NAME = 'share-splash-screen';

const ShareSplashStyles = css`
  :host {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    position: relative;

    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);

    font-size: calc(12px + 1vmin);
  }

  .container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #0e0e0e9e;
    color: white;
    border-radius: 40px;
    padding: 5vmin;
    margin: auto;
    text-align: center;

    width: 100vmin;
    max-width: 90%;

    position: relative;

    animation: scaleIn 0.5s ease-out;
  }

  .learn-more-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    padding: 32px;

    color: #999;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.24px;
    text-decoration-line: underline;
  }

  .header {
    margin-top: 1.5em;
    margin-bottom: 2px;
    font-size: 1.7em;
    font-family: Lora, serif;
    font-optical-sizing: auto;
  }

  .sub-header-big {
    margin-top: 20px;
    font-size: 2.5em;
    font-family: Lora, serif;
    font-optical-sizing: auto;
  }

  .sub-header-small {
    margin-bottom: 20px;
    font-size: 1.2em;
    font-family: Inter, sans-serif;
    font-weight: 200;
    color: #999;
  }

  .hero {
    margin-top: 6vmin;
    margin-bottom: 5vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .share-text {
    color: gray;
  }

  .share-buttons {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .share-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #00ff99;
  }

  .btn-advance {
    color: #999;
    cursor: pointer;
    text-decoration: none;
  }

  .skip-link {
    text-decoration: underline;
  }

  @keyframes scaleIn {
    0% {
      transform: scale(80%);
    }
    100% {
      transform: scale(100%);
    }
  }

  @media (max-width: 600px) {
    .learn-more-btn {
      font-size: 0.8em;
    }
    .header {
      margin-top: 2.5em;
      font-size: 1.1em;
    }
    .sub-header-big {
      font-size: 1.5em;
    }
    .sub-header-small {
      font-size: 1em;
    }
    .share-buttons {
      zoom: 0.75;
    }
    .btn-advance {
      zoom: 0.75;
    }
  }
`;

@customElement(TAG_NAME)
export class ShareSplash extends LitElement {
  @property({ type: String }) shifterName = '';
  @property({ type: String }) advanceMode = '' as 'pay' | 'continue' | 'skip' | '';

  static styles = ShareSplashStyles;

  public readonly onBuyPS1 = createDelegateFunction();
  public readonly onDone = createDelegateFunction();
  public readonly onShare = createDelegateFunction<[platform: SocialSharePlatform]>();
  public readonly onLearnMore = createDelegateFunction();

  renderAdvanceButton() {
    console.log('🚀 Rendering advance button:', this.advanceMode);

    switch (this.advanceMode) {
      case 'pay':
        return html`
          <glass-button class="btn-advance skip-link" @click="${this.onBuyPS1}">
            I'll pay $3 to support!
          </glass-button>
        `;
      case 'continue':
        return html`
          <glass-button class="btn-advance continue-link" @click="${this.onDone}">
            Continue
          </glass-button>
        `;
      case 'skip':
        return html`
          <glass-button class="btn-advance skip-link" @click="${this.onDone}">
            Maybe later
          </glass-button>
        `;
      default:
        return html``;
    }
  }

  render() {
    const hintString =
      'By sharing as an act of kindness, you inspire others to do the same, leading to a chain reaction of positive actions.';

    const onClickLearnMore = () => {
      this.onLearnMore.emit();
    };

    const shareService = createSocialSharingService();
    const sharePlatforms = shareService.getSupportedPlatforms();

    return html`
      <div class="container">
        <div id="learn-more-button" class="learn-more-btn" @click="${onClickLearnMore}">
          Learn More
        </div>

        <div class="header">
          The Presence Shift is a<br /><tooltip-element tooltip=${hintString}>
            <u>pay-it-forward community</u> </tooltip-element
          >, ${this.shifterName} 🙂
        </div>

        <div class="hero">
          <glowy-spinny-dot></glowy-spinny-dot>
          <div class="sub-header-big">Presence Shift 1</div>
          <div class="sub-header-small">The Infinite Perfection</div>
        </div>

        <div class="share-text">Please share with a friend(s)</div>
        <div class="share-text">to support the project</div> 
        <!-- <div class="share-text">or support the project</div> -->
        <!-- <div class="share-text">to unlock Presence Shift 1</div> -->

        <div class="share-buttons">
          ${sharePlatforms.map(platform => {
            return html`
              <social-icon
                id="share-button-${platform}"
                icon=${platform}
                @click="${() => this.onShare(platform)}"
              ></social-icon>
            `;
          })}
        </div>

        ${this.renderAdvanceButton()}
      </div>
    `;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.onBuyPS1.clear();
    this.onDone.clear();
    this.onShare.clear();
    this.onLearnMore.clear();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: ShareSplash;
  }
}

export async function displayShareSplash(parentElement: HTMLDivElement, shifterName: string) {
  const awaitable = createCustomAwaitable<void>();

  const paymentService = createStripePaymentService('accessPS1');
  const shareClicks = new Set<string>();

  const splashScreen = new ShareSplash();
  splashScreen.shifterName = shifterName || 'buddy';
  splashScreen.advanceMode = 'skip';
  parentElement.appendChild(splashScreen);

  const fader = createElementFadeInOutControl(splashScreen);

  const shareService = createSocialSharingService();
  splashScreen.onShare.on(async platform => {
    ConversionReporting.reportConversion(`share_on_${platform}`);

    shareClicks.add(platform);

    splashScreen.advanceMode = 'continue';

    await shareService.shareOnPlatform(platform, shifterName);
  });
  splashScreen.onBuyPS1.on(async () => {
    ConversionReporting.reportConversion('pay_ps1');

    await paymentService.performStripePayment();

    const paid = await paymentService.checkIfUserPaidSuccessfully();

    if (paid) {
      splashScreen.advanceMode = 'continue';
    } else {
      alert('Payment failed');
    }
  });
  splashScreen.onDone.on(async () => awaitable.resolve());

  splashScreen.onLearnMore.on(async () => {
    await fader.fadeOut(0.2);
    await displayLearnMoreSplash_ShareVersion(parentElement, shifterName);
    await fader.fadeIn(0.3);
  });

  //// in case the user has already paid, [continue] should already be available
  paymentService.checkIfUserPaidSuccessfully().then(paid => {
    // splashScreen.advanceMode = paid ? 'continue' : 'pay';
    splashScreen.advanceMode = paid ? 'continue' : 'skip';
  });

  await fader.fadeIn(0.6);
  await awaitable;
  await fader.fadeOut(0.6);

  parentElement.removeChild(splashScreen);

  return shareClicks.size > 0 ? [...shareClicks] : null;
}
