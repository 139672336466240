import { ChatBubble } from '@/html/playback/ChatBubble';

type BubbleProps = {
  message: string;
  className: string;
  author: string;
};

export class ChatBubblesManager {
  static readonly shifterAuthorValue = 'shifter';

  private bubbles = [] as ChatBubble[];

  constructor(private parentElement: Element) {}

  addBubble(props: BubbleProps) {
    const bubble = new ChatBubble();
    bubble.bubbleContent = props.message;
    bubble.bubbleStyle = props.className;
    bubble.bubbleAuthor = props.author;
    bubble.rightAlign = bubble.bubbleAuthor === ChatBubblesManager.shifterAuthorValue;

    // console.log('📝 Adding bubble:', bubble, props);

    this.parentElement.appendChild(bubble);

    this.bubbles.push(bubble);
    bubble.onDisposeHandlers.push(() => this.removeBubble(bubble));

    bubble.updateComplete.then(() => {
      this.updateBubbleCorners();
    });

    requestAnimationFrame(() => {
      const scrollView = this.parentElement.parentElement!;
      scrollView.scroll({ top: scrollView.scrollHeight + 100, behavior: 'smooth' });
    });

    return bubble;
  }

  private *iterateBubblesByThreesAndAlignment() {
    for (let i = 0; i < this.bubbles.length; i++) {
      const prevBubble = this.bubbles[i - 1];
      const currBubble = this.bubbles[i];
      const nextBubble = this.bubbles[i + 1];

      yield [
        currBubble?.rightAlign === prevBubble?.rightAlign ? prevBubble ?? null : null,
        currBubble,
        currBubble?.rightAlign === nextBubble?.rightAlign ? nextBubble ?? null : null,
      ] as const;
    }
  }

  updateBubbleCorners() {
    for (const [bPrev, bCurr, bNext] of this.iterateBubblesByThreesAndAlignment()) {
      if (bCurr.rightAlign) {
        bCurr.setHardCorners({
          topLeft: false,
          bottomLeft: false,
          topRight: !!bPrev,
          bottomRight: !!bNext,
        });
      } else {
        bCurr.setHardCorners({
          topLeft: !!bPrev,
          bottomLeft: !!bNext,
          topRight: false,
          bottomRight: false,
        });
      }
    }
  }

  removeBubble(bubble: ChatBubble) {
    const index = this.bubbles.indexOf(bubble);
    if (index !== -1) {
      this.bubbles.splice(index, 1);
    } else {
      console.warn(`Bubble not found in bubbles array.`, this.bubbles);
    }
  }

  clearAllBubbles() {
    return Promise.all(this.bubbles.map(bubble => bubble.hide({ duration: 0.8 })));
  }
}
