import gsap from 'gsap';

import { ShiftContext } from '@/context/shiftContext';
import { SkinColors } from '@/html/gui/SkinColors';
import { createPresenceSkinSetter } from '@/scene/setPresenceSkin';
import { createThreejsObjectTweenableWrapper } from '@/util/createThreejsObjectTweenableWrapper';
import { nextFrame } from '@/util/nextFrame';
import { GLTF } from 'three/examples/jsm/Addons.js';

export function createAnimatedSkinChanger(
  shiftContext: ShiftContext,
  target: GLTF,
  style: 'pop' | 'fade'
) {
  const skinsHolder = shiftContext.gltfs?.dolphin1_skins.scene;
  const skinSetter = createPresenceSkinSetter(skinsHolder!);

  const setSkinColorAnimatedly1 = async (skin: SkinColors) => {
    const tweenable = createThreejsObjectTweenableWrapper(target.scene);

    const originalScale = target.scene.scale.x;

    await gsap.to(tweenable, { scaleAll: 0, duration: 0.2, ease: 'power2.in' });

    skinSetter.applySkinTo(target.scene, skin);

    await nextFrame();
    await gsap.to(tweenable, { scaleAll: originalScale, duration: 0.4, ease: 'back.out' });
  };

  const setSkinColorAnimatedly2 = async (skin: SkinColors) => {
    {
      const tweenable = createThreejsObjectTweenableWrapper(target.scene);

      await gsap.to(tweenable, { opacity: 0, duration: 0.6, ease: 'power2.inOut' });

      target.scene.visible = false;
      tweenable.opacity = 1;
    }

    skinSetter.applySkinTo(target.scene, skin);

    {
      const tweenable = createThreejsObjectTweenableWrapper(target.scene);

      target.scene.visible = true;
      tweenable.opacity = 0;

      await gsap.to(tweenable, { opacity: 1, duration: 0.9, ease: 'power2.inOut' });
    }
  };

  return {
    setSkinColorAnimatedly: style === 'pop' ? setSkinColorAnimatedly1 : setSkinColorAnimatedly2,
  };
}
