import gsap from 'gsap';

import { LitElement, css, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import Showdown from 'showdown';

import { createDelegateFunction } from '@/util/core/createDelegateFunction';

const TAG_NAME = 'error-message';

const mdConverter = new Showdown.Converter();

export async function displayErrorMessage(
  textContent: string,
  textShort: string = 'Please try again later'
): Promise<void> {
  const parentElement = document.body;

  const errorMessage = document.createElement(TAG_NAME) as ErrorMessage;
  errorMessage.textContent = textContent;
  errorMessage.textShort = textShort;
  parentElement.appendChild(errorMessage);

  gsap.from(errorMessage, { duration: 0.8, opacity: 0, scale: 0, ease: 'elastic.out' });

  await errorMessage.handleClick.waitUntil();

  await gsap.to(errorMessage, { duration: 0.4, opacity: 0, scale: 0, ease: 'back.in' });

  parentElement.removeChild(errorMessage);
}

class ErrorMessage extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      pointer-events: none;

      z-index: 10000001;
    }

    .container {
      box-sizing: border-box;

      text-align: center;
      padding: 3vmin 4vmin;
      padding-bottom: 1.5vmin;
      max-width: 720px;
      margin: 1vmin;

      background: rgba(0, 0, 0, 0.8);
      border-radius: 20px;
      border: 2px solid rgba(255, 255, 255, 0.2);
    }

    .mini-title {
      color: #fff9;
      font-size: 0.7em;
      font-weight: 400;
      font-family: Inter, 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-optical-sizing: auto;
      margin-bottom: 1em;
    }

    .context {
      color: white;
      font-size: 1.2em;
      font-family: serif;
      font-weight: 400;
      font-family: Inter, 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-optical-sizing: auto;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    button {
      margin-top: 2em;
      padding: 0.5em 1em;
      font-size: 1em;
      color: white;
      background-color: red;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      pointer-events: all;
    }
  `;

  public readonly handleClick = createDelegateFunction();

  public textContent = '';
  public textShort = '';

  render() {
    const htmlTextContent = mdConverter.makeHtml(this.textContent);
    const htmlTextShort = mdConverter.makeHtml(this.textShort);
    return html` <div class="container">
      <div class="mini-title">${unsafeHTML(htmlTextContent)}</div>
      <div class="context">${unsafeHTML(htmlTextShort)}</div>
      <button @click=${this.handleClick}>OK</button>
    </div>`;
  }
}

customElements.define(TAG_NAME, ErrorMessage);
