import gsap from 'gsap';

import { LitElement, css, html } from 'lit';
import { property } from 'lit/decorators.js';

import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { trackOrientationAsCSSClass } from '@/util/trackOrientationAsCSSClass';

import './components/TOSFooter';

const TAG_NAME = 'continue-with-email-splash-screen';

class ContinueWithEmail extends LitElement {
  public static readonly styles = css`
    :host {
      --splash-screen-padding: 0vmin;

      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: 'Helvetica Neue', sans-serif;

      background: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);

      zoom: 0.7;
    }

    .container {
      box-sizing: border-box;
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 3em;
      text-align: center;
      color: white;

      max-height: 80%;
    }

    .title {
      font-size: 3.7em;
      text-shadow: 0 2px 1rem #0009;
      margin-bottom: 4rem;

      font-family: 'Lora', serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;

      transition: font-size 0.2s;
    }

    .subtitle {
      font-size: 1.6em;
      text-shadow: 0 2px 1rem #0009;
      margin-top: 1rem;
      margin-bottom: 1.4rem;

      font-family: 'Inter', sans-serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-style: normal;

      color: #cdcdcd;
    }

    .subtitle.email {
      margin-top: 3em;
      margin-bottom: 0;
      font-size: 1.2em;
    }

    .container .continue-button {
      margin-top: 3rem;
      padding: 0.75em 1.25em;
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      border: none;
      border-radius: 250px;
      cursor: pointer;
      transition:
        color 0.2s,
        padding 0.2s;

      font-family: Inter;
      font-size: 32px;
      font-weight: 200;
      line-height: 24px;
      text-align: left;
      margin-bottom: 3rem;
    }

    .container .continue-button:hover {
      color: cyan;
      padding: 0.75em 1.5em;
    }

    :host(.portrait) {
      .title {
        font-size: 2.2rem;
      }
      .subtitle {
        font-size: 0.9rem;
      }
    }

    .email-input-container {
      margin-top: 4em;
      margin-bottom: 0.5em;
      width: 90%;
      max-width: 500px;
    }
  `;

  public readonly onBegin = createDelegateFunction<[email: string]>();

  @property({ type: Boolean })
  public userName = '';

  private emailValue = '';

  render() {
    return html`
      <div class="container">
        <div class="content">
          <div class="title">Thanks for paying it forward, ${this.userName}!</div>
          <div class="subtitle ready">Presence Shift 1 is ready for you.</div>
        </div>
        <div class="email-input-container">
          <modern-input
            label="Enter your email to begin"
            name="email"
            type="email"
            placeholder="my@email.com"
            @input-change=${this.handleInputChange}
          ></modern-input>
        </div>
        <button
          id="continue-button-email-collection"
          class="continue-button"
          @click=${() => this.onBegin.emit(this.emailValue)}
        >
          Begin ❯
        </button>
        <tos-footer></tos-footer>
      </div>
    `;
  }

  private handleInputChange(e: Event) {
    const input = e.target as HTMLInputElement;
    this.emailValue = input.value;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.onBegin.clear();
  }
}

customElements.define(TAG_NAME, ContinueWithEmail);

export async function displayContinueWithEmail(parentElement: HTMLDivElement, userName: string) {
  const awaitable = createCustomAwaitable<string>();

  const splashScreen = new ContinueWithEmail();
  splashScreen.onBegin.on(async email => awaitable.resolve(email));
  splashScreen.userName = userName || 'friend';
  parentElement.appendChild(splashScreen);
  trackOrientationAsCSSClass(splashScreen);

  await gsap.from(splashScreen, { duration: 0.5, opacity: 0 });

  const email = await awaitable;
  console.log('📧 Email:', email);

  await gsap.to(splashScreen, { duration: 0.5, opacity: 0 });
  parentElement.removeChild(splashScreen);
}
