import { FastforwardingControl } from '@/services/createFastForwardingControl';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';

export function createFastForwardingInteractionManager(
  hitAreaElements: Element[],
  control: FastforwardingControl
) {
  const onCantFastForward = createDelegateFunction();

  const handleCanFastForwardChange = (canFastForward: boolean) => {
    console.log('🔄 Can fast forward:', canFastForward);

    for (const hitAreaElement of hitAreaElements) {
      hitAreaElement.classList.toggle('disabled', !canFastForward);
    }

    if (!canFastForward) {
      onCantFastForward.emit();
    } else {
      for (const hitAreaElement of hitAreaElements) {
        const offElementPointerHold = onElementPointerHold(
          hitAreaElement,
          250,
          () => {
            if (!control.canFastForward.get()) return;
            if (control.isFastForwarding.get()) return;
            control.start();
          },
          () => {
            if (!control.isFastForwarding.get()) return;
            control.stop();
          }
        );
        onCantFastForward.once(() => offElementPointerHold());
      }
    }
  };

  control.canFastForward.onChange.on(handleCanFastForwardChange);
  handleCanFastForwardChange(control.canFastForward.get());

  return function remove() {
    control.canFastForward.onChange.off(handleCanFastForwardChange);
    
    onCantFastForward.emit();
    onCantFastForward.clear();
  };
}

/**
 * Calls the callback when the user holds the element for a certain amount of time.
 */
function onElementPointerHold(
  element: Element,
  holdTriggerDuration: number,
  holdCallback: () => void,
  releaseCallback: () => void
) {
  let timer: number | null = null;

  const startTimer = () => {
    if (timer !== null) {
      clearTimer();
    }

    timer = window.setTimeout(() => {
      holdCallback();
      timer = null;
    }, holdTriggerDuration);
  };

  const clearTimer = () => {
    if (timer !== null) {
      window.clearTimeout(timer);
      timer = null;
    } else {
      releaseCallback();
    }
  };

  element.addEventListener('mousedown', startTimer);
  element.addEventListener('touchstart', startTimer);
  element.addEventListener('mouseup', clearTimer);
  element.addEventListener('touchend', clearTimer);
  element.addEventListener('pointerout', clearTimer);

  return () => {
    element.removeEventListener('mousedown', startTimer);
    element.removeEventListener('touchstart', startTimer);
    element.removeEventListener('mouseup', clearTimer);
    element.removeEventListener('touchend', clearTimer);
    element.removeEventListener('pointerout', clearTimer);
  };
}
