import { buildVars } from '@/constants/buildVars';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createIndexedDBService } from '@/util/core/createIndexedDBService';
import { MultipleReasons } from '@/util/core/MultipleReasons';

export function createTextToSpeechService() {
  const indexedDBServiceAwaitable = createIndexedDBService('indexedAssets', 'elevenlabs');
  const ongoingSetOperations = createOngoingAwaitablesDictionary();

  const REGULAR_MUTE_REASON = 'manually-muted';
  const reasonsToMute = new MultipleReasons();

  return {
    reasonsToMute,

    setMuted(shouldMute: boolean) {
      if (this.isMuted === shouldMute) {
        return;
      }

      reasonsToMute.set(REGULAR_MUTE_REASON, shouldMute);
    },

    get isMuted() {
      return reasonsToMute.has(REGULAR_MUTE_REASON);
    },

    async generateAudioAndSaveToDB(text: string) {
      try {
        const indexedDBService = await indexedDBServiceAwaitable;

        console.log('🎤 Generating audio file from text:', text);

        const idbKey = text;

        const awaitable = ongoingSetOperations.add(idbKey);

        const audioArrayBuffer = await generateAudioFromText(text);

        await indexedDBService.setArrayBuffer(idbKey, audioArrayBuffer);

        awaitable.resolve();

        return audioArrayBuffer;
      } catch (error) {
        console.error('🎤 Failed to generate audio from text:', text, error);

        const idbKey = text;
        ongoingSetOperations.delete(idbKey);

        return null;
      }
    },

    async getGeneratedAudioFor(text: string) {
      const indexedDBService = await indexedDBServiceAwaitable;

      const idbKey = text;

      await ongoingSetOperations.get(idbKey);

      const audioArrayBuffer = await indexedDBService.getArrayBuffer(idbKey);
      return audioArrayBuffer;
    },

    async playAudioFor(key: string) {
      try {
        if (!key || reasonsToMute.hasAny()) {
          return Promise.resolve();
        }

        let audioArrayBuffer = await this.getGeneratedAudioFor(key);
        if (!audioArrayBuffer) {
          audioArrayBuffer = await this.generateAudioAndSaveToDB(key);
        }

        if (!audioArrayBuffer) {
          return Promise.resolve();
        }

        await playAudioFromArrayBuffer(audioArrayBuffer);
      } catch (error) {
        console.error('🎤 Failed to play AI audio:', key, error);
      }
    },
  };
}

function createOngoingAwaitablesDictionary() {
  const ongoingAwaitables: Record<string, any> = {};

  return {
    add(key: string) {
      const awaitable = createCustomAwaitable();

      if (ongoingAwaitables[key]) {
        throw new Error('Awaitable already exists for key: ' + key);
      }

      ongoingAwaitables[key] = awaitable as any;

      awaitable.then(() => delete ongoingAwaitables[key]);

      return awaitable;
    },
    get: (key: string) => ongoingAwaitables[key],
    delete: (key: string) => delete ongoingAwaitables[key],
  };
}

// const VOICE_ID = 'Xb7hH8MSUJpSbSDYk0k2'; // default
// const API_KEY = 'c2bacf0186170133b9d46cbb07d5d652'; // cx
async function generateAudioFromText(text: string) {
  const VOICE_ID = buildVars.elevenLabsVoiceId;
  if (!VOICE_ID) throw new Error('11labs: Voice ID not specified');

  const API_KEY = buildVars.elevenLabsApiKey;
  if (!API_KEY) throw new Error('11labs: API key not specified');

  const body = {
    previous_text: 'It’s a privilege to Presence Shift with you, ',
    next_text: '.',
    text,
    model_id: 'eleven_turbo_v2',
  };

  const options = {
    method: 'POST',
    headers: { 'xi-api-key': API_KEY, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const fetchUrl = 'https://api.elevenlabs.io/v1/text-to-speech/' + VOICE_ID;
  const fetchResponse = await fetch(fetchUrl, options);
  if (!fetchResponse.ok) {
    throw new Error('Failed to fetch audio file');
  }

  const arrayBuffer = await fetchResponse.arrayBuffer();
  return arrayBuffer;
}

import { Howl } from 'howler';

async function playAudioFromArrayBuffer(arrayBuffer: ArrayBuffer) {
  const blob = new Blob([arrayBuffer], { type: 'audio/mp3' });
  const url = URL.createObjectURL(blob);

  return new Promise<void>(resolve => {
    const sound = new Howl({
      src: [url],
      format: ['mp3'],
      onend: () => {
        URL.revokeObjectURL(url);
        resolve();
      },
    });

    sound.play();
  });
}

// async function playAudioFromArrayBuffer(arrayBuffer: ArrayBuffer) {
//   const audioContext = new AudioContext();

//   // Ensure audio context is resumed after user interaction
//   if (audioContext.state === 'suspended') {
//     await audioContext.resume();
//   }

//   const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
//   const source = audioContext.createBufferSource();
//   source.buffer = audioBuffer;
//   source.connect(audioContext.destination);

//   return new Promise<void>(resolve => {
//     source.onended = () => {
//       resolve();
//     };
//     source.start();
//   });
// }
