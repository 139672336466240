import gsap from 'gsap';

import { SceneContext } from '@/context/sceneContext';

export function createSceneLightsPropsWrapper(sceneContext: SceneContext | null) {
  if (!sceneContext) {
    return {
      intensity: 1,
      fadeIntensityTo: async () => {},
    };
  }
  
  const { sceneCtrl } = sceneContext;

  const originalIntensities = [
    sceneCtrl.lights.ambientLight.intensity,
    sceneCtrl.lights.directionalLight.intensity,
    sceneCtrl.lights.spotlight.intensity,
  ] as const;

  const lightPropsStore = {
    _value: 1,
    get intensity() {
      return this._value;
    },
    set intensity(newValue) {
      this._value = newValue;
      sceneCtrl.lights.ambientLight.intensity = 0.5 + 0.5 * newValue * originalIntensities[0];
      sceneCtrl.lights.directionalLight.intensity = newValue * originalIntensities[1];
      sceneCtrl.lights.spotlight.intensity = newValue * originalIntensities[2];
    },
    fadeIntensityTo(newValue: number, duration: number) {
      return gsap.to(this, {
        intensity: newValue,
        duration,
        ease: 'power2.inOut',
      });
    },
  };

  // gsap.to(lightPropsStore, { intensity: 0.2, duration: 3.0, ease: 'power2.inOut' });

  return lightPropsStore;
}
