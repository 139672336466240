import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('glowy-spinny-dot')
export class GlowySpinnyDot extends LitElement {
  static styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 80px;
      height: 80px;
      pointer-events: none;
    }

    img.glowdot {
      z-index: 2;
      animation: spin 30s linear infinite;
    }

    .glowdot-overlay {
      position: absolute;
      z-index: 3;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  @property({ type: Number }) scale = 1;

  render() {
    return html`
      <img
        class="glowdot"
        src="/images/tap.png"
        alt="Presence Shift Image"
        style="zoom: ${this.scale}"
      />
      <div class="glowdot-overlay">
        <slot></slot>
      </div>
    `;
  }
}
