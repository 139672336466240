import { checkPhoneVerificationCode, linkPhoneNumberToUser } from '@/backend/auth-with-phone';
import { appContext } from '@/context/appContext';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { GetPresenceShift2 } from './components/GetPresenceShift2';
import { SubscribeForPresenceShift2 } from './components/SubscribeForPresenceShift2';
import { displayErrorMessage } from './system/displayErrorMessage';
import { createElementFadeInOutControl } from './utils/createElementFadeInOutControl';

export async function displayGetNextPresenceShiftSplash(
  parentElement: HTMLDivElement,
  shifterName: string
) {
  const awaitable = createCustomAwaitable<void>();

  const splash = document.createElement(SubscribeForPresenceShift2.tagName);
  splash.shifterName = shifterName;
  parentElement.appendChild(splash);

  async function handleError(error: unknown) {
    console.error('💀 Error:', error);
    await displayErrorMessage(String(error), 'Something went wrong.');
    splash.loading = false;
  }

  splash.onSubmit.on(async formValues => {
    console.log('📝 Form values:', formValues);

    const phoneNumber = formValues.phone;
    try {
      await performUserPhoneNumberWorkflow(phoneNumber);
      awaitable.resolve();
    } catch (error) {
      await handleError(error);
    }
  });

  splash.onSkipClick.on(() => awaitable.resolve());

  const fader = createElementFadeInOutControl(splash);
  await fader.fadeIn(0.6);

  await awaitable;

  await fader.fadeOut(0.6);
  splash.remove();
}

export async function displayGetNextPresenceShiftSplashWithEmail(
  parentElement: HTMLDivElement,
  shifterName: string
) {
  const awaitable = createCustomAwaitable<void>();

  const splash = new GetPresenceShift2();
  splash.slot;
  splash.shifterName = shifterName;
  parentElement.appendChild(splash);

  async function handleError(error: unknown) {
    await displayErrorMessage(String(error), 'Something went wrong.');
    splash.setBusyState(false);
  }

  splash.onSubmit.on(async formValues => {
    console.log('📝 Form values:', formValues);

    let somethingErrored = false;

    if (!formValues.phone && !formValues.email) {
      await handleError('Please enter your phone number or email address.');
      return;
    }

    if (formValues.email) {
      appContext.userData.profile.setFieldValue('email', formValues.email);
    }

    if (formValues.phone) {
      const phoneNumber = formValues.phone;
      try {
        await performUserPhoneNumberWorkflow(phoneNumber);
      } catch (error) {
        await handleError(error);
        somethingErrored = true;
      }
    }

    if (somethingErrored) {
      return;
    }

    awaitable.resolve();
  });

  splash.onMaybeLaterClick.on(() => {
    awaitable.resolve();
  });

  const fader = createElementFadeInOutControl(splash);
  await fader.fadeIn(0.6);

  await awaitable;

  await fader.fadeOut(0.6);
  splash.remove();
}

async function performUserPhoneNumberWorkflow(phoneNumber: string) {
  phoneNumber = phoneNumber.replace(/[^\d+]/g, '');

  if (!phoneNumber) {
    throw new Error('Please provide a valid phone number.');
  }

  appContext.userData.profile.setFieldValue('phone', phoneNumber);

  const confirmationResult = await linkPhoneNumberToUser(phoneNumber);
  if (!confirmationResult) {
    throw new Error('Confirmation result is null');
  }

  const verificationCode = prompt('📝 Please enter the verification code you received via SMS.');
  if (!verificationCode) {
    throw new Error('Verification code not provided');
  }

  await checkPhoneVerificationCode(confirmationResult, verificationCode);
}
