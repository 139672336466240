import Showdown from 'showdown';

import { appContext } from '@/context/appContext';
import { delay } from '@/util/delay';

import { ShiftContext } from '@/context/shiftContext';
import { defaultScriptItemProperties } from '@/data/defaultScriptItemProperties';
import { interruptableDelay } from '@/debug/util/interruptableDelay';
import { createFlatStringyVarsContext } from '@/services/createStringyVarsContext';
import { processStringWithVars } from '@/tools/processString';
import { createAnswerProcessorDictionary } from './createAnswerProcessorDictionary';
import { createTriggerResolverDictionary } from './createTriggerResolverDictionary';
import { iterateSequenceScriptItems } from './iterateSequenceScriptItems';
import { updateWatchedVar } from '@/debug/debug-box';
import { during } from '@/util/core/during';
import { AwaitablesTracker } from '@/util/core/AwaitablesTracker';

let scriptSequenceCurrentlyRunning = false;

const mdConverter = new Showdown.Converter();

export async function playShiftScript(
  partialScript: Partial<SequenceScript[number]>[],
  shiftContext: ShiftContext
) {
  const { fastforwarder } = appContext;
  const { playerCtrl, events, userAnswers } = shiftContext;

  if (scriptSequenceCurrentlyRunning) {
    console.log('🎥 Animation already running!');
    return;
  }

  scriptSequenceCurrentlyRunning = true;

  try {
    const script = partialScript;

    events.playScriptStarted(script);

    console.log('🐬 Starting mock animation...');

    await delay(0.5);

    const triggerResolver = createTriggerResolverDictionary(shiftContext);

    const answerProcessors = createAnswerProcessorDictionary();

    for (const { scriptItem, scriptItemIndex } of iterateSequenceScriptItems(
      script,
      shiftContext
    )) {
      try {
        await playSequenceScriptItem(scriptItem, scriptItemIndex);
      } catch (error) {
        console.error('🐬 Error playing sequence script item:', error);
      }
    }

    async function playTheVoiceStuff(scriptItem: SequenceScriptItem) {
      const { voice, aiVoice, aiVoiceBefore, aiVoiceAfter } = scriptItem;

      if (aiVoiceBefore) {
        const text = processStringWithVars(aiVoiceBefore, shiftContext, '', false);
        if (text) {
          await appContext.aiVoice.playAudioFor(text);
        }
      }

      if (voice) {
        if (appContext.voice.hasVoiceLine(voice)) {
          await appContext.voice.playVoiceLine(voice);
        } else {
          await appContext.sfx.playSoundBite('voice-test/' + voice);
        }
      }

      if (aiVoice) {
        const text = processStringWithVars(aiVoice, shiftContext, '', false);
        if (text) {
          await appContext.aiVoice.playAudioFor(text);
        }
      }

      if (aiVoiceAfter) {
        const text = processStringWithVars(aiVoiceAfter, shiftContext, '', false);
        if (text) {
          await appContext.aiVoice.playAudioFor(text);
        }
      }
    }

    function createNestedMessagesControl({ nested }: SequenceScriptItem) {
      if (!nested?.length) {
        throw new Error('No nested messages found.');
      }

      const queue = [...nested];
      let cancelled = false;

      return {
        async play() {
          while (queue.length) {
            const nextItem = queue.shift();
            if (!nextItem) break;

            await delay(nextItem.delayBefore ?? 1.0);
            if (cancelled) break;

            const awaitables = [] as Promise<void>[];

            if (nextItem.voice) {
              const awaitable = appContext.voice.playVoiceLine(nextItem.voice);
              awaitables.push(awaitable);
            }

            if (nextItem.message) {
              const messageText = processStringWithVars(nextItem.message, shiftContext, '', false);
              const messageHtml = mdConverter.makeHtml(messageText);
              const bubble = playerCtrl.addChatItem({ message: messageHtml });

              const awaitable = bubble.show(defaultScriptItemProperties.in);
              awaitables.push(awaitable);
            }

            await Promise.allSettled(awaitables);
            awaitables.length = 0;
            if (cancelled) break;

            await delay(nextItem.delayAfter ?? 1.0);
            if (cancelled) break;
          }
        },
        cancel() {
          cancelled = true;
        },
      };
    }

    async function playSequenceScriptItem(scriptItem: SequenceScriptItem, scriptItemIndex: number) {
      events.playScriptItemStarted(scriptItem, scriptItemIndex);

      const awaitablesToWaitForBeforeMovingOn = new AwaitablesTracker();

      console.debug('🐬 Script item: ', [scriptItemIndex], scriptItem);

      async function handleUserTextInput() {
        if (!scriptItem.userInputKey) {
          return;
        }

        const previouslyInputtedValue = userAnswers.getValue(scriptItem.userInputKey);

        if (previouslyInputtedValue && fastforwarder.isFastForwarding.get()) {
          //// If the user has previously answered the question, and we're currently fast-forwarding,
          //// we don't want to wait for the user to answer again.
          ////
          //// Doing nothing.
          ////
        } else {
          const nestedMessagesCtrl = !scriptItem.nested?.length
            ? null
            : createNestedMessagesControl(scriptItem);
          const nestedMessagesAwaitable = voiceAwaitable.then(
            () => nestedMessagesCtrl?.play() ?? Promise.resolve()
          );
          awaitablesToWaitForBeforeMovingOn.push(nestedMessagesAwaitable, 'nested messages');

          const userInputAwaitable = playerCtrl.waitUntilUserAnswer(
            scriptItem.userInputKey,
            previouslyInputtedValue ?? undefined
          );
          const userInput = await userInputAwaitable;
          console.log({ userInput });
          if (userInput === '' || userInput === undefined || userInput === null) {
            userAnswers.setValue(scriptItem.userInputKey, null);
          } else {
            userAnswers.setValue(scriptItem.userInputKey, userInput);
          }

          nestedMessagesCtrl?.cancel();

          const processorFunc = answerProcessors[scriptItem.userInputKey];
          if (processorFunc) {
            try {
              const vars = createFlatStringyVarsContext(shiftContext);
              const processorUpdates = await processorFunc(userInput, vars);
              if (processorUpdates) {
                for (const [key, value] of Object.entries(processorUpdates)) {
                  userAnswers.setValue(key, value);
                }
              }
            } catch (error) {
              console.warn("🐬 User's input processor threw an error:", error);
            }
          }

          await Promise.allSettled([nestedMessagesAwaitable, delay(0.4)]);

          await playerCtrl.submitUserAnswer(scriptItem.userInputKey, userInput);

          await delay(2.0);
        }
      }

      updateWatchedVar('playback', 'clearing history');
      if (scriptItem.clearHistory) {
        await playerCtrl.clearChatItems();
      }

      if (scriptItem.trigger && triggerResolver) {
        const triggerAwaitable = triggerResolver
          .resolveTrigger(
            scriptItem.trigger,
            scriptItem.triggerArgs ?? [],
            scriptItem.triggerInBackground ?? false
          )
          .catch((error: any) =>
            console.error('🐬 CAPTURED ERROR 🐬\nTrigger function error:', error)
          );
        awaitablesToWaitForBeforeMovingOn.push(triggerAwaitable, 'trigger');
      }

      updateWatchedVar('playback', 'delay before: ' + scriptItem.delayBefore);
      await delay(scriptItem.delayBefore ?? 0.0);

      const voiceAwaitable = playTheVoiceStuff(scriptItem);
      awaitablesToWaitForBeforeMovingOn.push(voiceAwaitable, 'voiceline(s)');

      if (scriptItem.message) {
        updateWatchedVar('playback', 'bubble show');
        const messageText = processStringWithVars(scriptItem.message, shiftContext, '', false);
        const messageHtml = mdConverter.makeHtml(messageText);
        const bubble = playerCtrl.addChatItem({ ...scriptItem, message: messageHtml });
        await bubble.show(scriptItem.in!);

        updateWatchedVar('playback', 'delay time-on-screen ' + scriptItem.timeOnScreen);
        if (scriptItem.timeOnScreen) {
          await delay(scriptItem.timeOnScreen);
        }

        const waitForUserTap =
          !appContext.userPrefs.autoAdvanceScript &&
          !scriptItem.dontWaitForUserTap &&
          !fastforwarder.isFastForwarding.get();

        if (scriptItem.userInputKey) {
          updateWatchedVar('playback', 'user input');
          await handleUserTextInput();
        } else if (waitForUserTap) {
          updateWatchedVar('playback', 'wait-for-user-tap');
          await playerCtrl.waitUntilUserTap();
        }
      } else {
        if (scriptItem.userInputKey) {
          updateWatchedVar('playback', 'user input');
          await handleUserTextInput();
        }

        if (scriptItem.waitForUserTapEvenIfNoMessage) {
          updateWatchedVar('playback', `awaiting ${awaitablesToWaitForBeforeMovingOn}`);
          await awaitablesToWaitForBeforeMovingOn.awaitAll();

          updateWatchedVar('playback', 'wait-for-user-tap');
          await playerCtrl.waitUntilUserTap();
        }
      }

      await during(
        //
        awaitablesToWaitForBeforeMovingOn.awaitAll(),
        () => updateWatchedVar('playback', `awaiting ${awaitablesToWaitForBeforeMovingOn}`)
      );

      const waitForUserTap =
        !appContext.userPrefs.autoAdvanceScript &&
        !scriptItem.dontWaitForUserTap &&
        !fastforwarder.isFastForwarding.get(); // TODO: DRY
      const defaultDelayAfter =
        !scriptItem.message || scriptItem.userInputKey || waitForUserTap ? 0.1 : 0.25;
      const delayAfter = scriptItem.delayAfter ?? defaultDelayAfter;
      updateWatchedVar('playback', 'delay after: ' + delayAfter);
      await delay(delayAfter);

      if (appContext.urlParams.dev) {
        if (scriptItem.__breakpoint__ !== undefined) {
          updateWatchedVar('playback', 'breakpoint');
          if (scriptItem.__breakpoint__ === 'click') {
            await interruptableDelay(999999);
          } else {
            await delay(999999);
          }
        }
      }

      events.playScriptItemFinished(scriptItem, scriptItemIndex);
    }

    events.playScriptFinished(script);

    triggerResolver.dispose();
  } finally {
    scriptSequenceCurrentlyRunning = false;
  }
}
