import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { LitElement, css, html } from 'lit-element';
import { customElement, property } from 'lit/decorators.js';

const TAG_NAME = 'shifter-input-pick-option';
declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: ShifterInputPickOption;
  }
}

@customElement(TAG_NAME)
export class ShifterInputPickOption extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    button {
      display: block;
      margin: 0 1vmin;
      pointer-events: all;
      cursor: pointer;

      font-size: 1.25em;
      padding: 0.75rem 1.5rem;
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      transition:
        color 0.2s,
        opacity 0.2s;

      font-family: Inter;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-bottom: 3rem;
    }

    button:hover {
      color: cyan;
    }

    button[disabled] {
      opacity: 0;
      pointer-events: none;
    }
  `;

  @property({ type: Array })
  buttonDescriptors: ButtonDescriptor[] = [];

  public readonly onOptionPicked = createDelegateFunction<[value: unknown]>();

  render() {
    return html`
      ${this.buttonDescriptors.map(
        descriptor => html`
          <button @click="${() => this.onOptionPicked.emit(descriptor.value)}">
            ${descriptor.label}
          </button>
        `
      )}
    `;
  }
}

interface ButtonDescriptor {
  label: string;
  value: any;
}
