import { LitElement, css, html } from 'lit';

import gsap from 'gsap';

const TAG_NAME = 'fastforwarding-indicator';

export function addFastForwardingIndicator(parentElement: HTMLElement) {
  const indicator = document.createElement(TAG_NAME);
  parentElement.appendChild(indicator);

  return async function remove() {
    await gsap.to(indicator, { duration: 0.3, opacity: 0 });
    parentElement.removeChild(indicator);
  };
}

class FastforwardingIndicator extends LitElement {
  static styles = css`
    :host {
      box-sizing: border-box;
      position: absolute;
      bottom: 0%;
      left: 0;
      width: 100vw;
      height: 24vmin;
      
      overflow: hidden;
      pointer-events: none;
      
      animation: fadeIn 0.4s linear;
    }

    .inner {
      width: 100%;
      height: 100%;
      background: url(/images/ff-arrows-tilable.webp) repeat-x;
      background-size: auto 100%;
      animation: slide 40s linear infinite;
      opacity: 0.2;
    }

    @keyframes slide {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: 1000% 0;
      }
    }
  `;

  render() {
    return html` <div class="inner"></div> `;
  }
}

customElements.define(TAG_NAME, FastforwardingIndicator);
