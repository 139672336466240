import { Group } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import type { GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { getFirebaseStorageAssetUrl } from '@/backend/getFirebaseStorageAssetUrl';

const defaultAssetsManifest = {
  fart: 'Fart.glb',
  dolphin1_skins: 'Dolphin Skins.glb',
  dolphin1: 'Dolphin1.glb',
  dolphin2: 'Dolphin2.glb',
  penguin: 'Penguin.glb',
  home: 'Environment.glb',
};

type AssetsManifest = typeof defaultAssetsManifest;
type LoadedAssetsFrom<T> = {
  [K in keyof T]: GLTF;
};

let loadingPromise: Promise<LoadedAssetsFrom<AssetsManifest>> | null = null;

async function loadAllAssets<T extends { [key: string]: string }>(
  dict: T
): Promise<LoadedAssetsFrom<T>> {
  const loader = new GLTFLoader();
  const promises = Object.entries(dict).map(async ([key, fileName]) => {
    let url = undefined as string | undefined;
    try {
      const cacheBuster = Math.random().toString(36).substring(2);
      url = (await getFirebaseStorageAssetUrl(fileName)) + '?' + cacheBuster;
      const gltf = await loader.loadAsync(url);
      return [key, gltf];
    } catch (error) {
      console.error(`Failed to load asset "${key}" (${fileName}) from URL "${url}"`, error);
      return [
        key,
        {
          scene: new Group(),
          animations: [],
          userData: {},
          cameras: [],
          parser: {},
          asset: {},
        },
      ];
    }
  });
  const loadedAssets = await Promise.all(promises);
  return Object.fromEntries(loadedAssets);
}

export function ensureAllAssetsLoaded() {
  if (!loadingPromise) {
    loadingPromise = loadAllAssets(defaultAssetsManifest);
  }
  return loadingPromise;
}

export type LoadedAssets = LoadedAssetsFrom<AssetsManifest>;
