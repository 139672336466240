import { MultipleReasons } from '@/util/core/MultipleReasons';
import { Howl, HowlOptions } from 'howler';

const REGULAR_MUTE_REASON = 'manually-muted';

export class VoiceoverService {
  public readonly reasonsToMute = new MultipleReasons();

  private readonly _howlInstance: Howl;

  constructor(public readonly howlOptions: HowlOptions) {
    this._howlInstance = new Howl(howlOptions);
    this.reasonsToMute.on({
      nonEmpty: () => this.stopCurrent(),
    });
  }

  setMuted(shouldMute: boolean) {
    if (this.isMuted === shouldMute) {
      return;
    }

    this.reasonsToMute.set(REGULAR_MUTE_REASON, shouldMute);
  }

  get isMuted() {
    return this.reasonsToMute.has(REGULAR_MUTE_REASON);
  }

  playVoiceLine(key: string): Promise<void> {
    if (this.reasonsToMute.hasAny()) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      const soundId = this._howlInstance.play(key);

      if (soundId === null) {
        console.warn('🎤 Failed to play sound:', key);

        return resolve();
      } else {
        console.log('🎤 Playing sound sprite:', soundId);

        this._howlInstance.once('end', id => {
          if (id !== soundId) return;
          resolve();
        });

        this._howlInstance.once('stop', () => {
          resolve();
        });
      }
    });
  }

  hasVoiceLine(key: string): boolean {
    return this.howlOptions.sprite?.[key] !== undefined;
  }

  stopCurrent(): void {
    this._howlInstance.stop();
  }
}
