import { appContext } from '@/context/appContext';
import { ShiftContext } from '@/context/shiftContext';
import { createPresenceSkinSetter } from '@/scene/setPresenceSkin';
import { ShifterInputPickSkin } from './ShifterInputPickSkin';
import { createElementFadeInOutControl } from '../utils/createElementFadeInOutControl';

export async function displaySkinPicker(parentElement: Element, shiftContext: ShiftContext) {
  const unblockFF =
    appContext.fastforwarder.reasonsToPreventFastForwarding.add('display-skin-picker');

  const skinPicker = new ShifterInputPickSkin();
  parentElement.append(skinPicker);

  const { dolphin1, dolphin1_skins } = shiftContext.gltfs || {};
  if (dolphin1 && dolphin1_skins) {
    const skinSetter = createPresenceSkinSetter(dolphin1_skins.scene);
    skinPicker.onChangeSelection.on(skinSlug => skinSetter.applySkinTo(dolphin1.scene, skinSlug));
  }

  await skinPicker.updateComplete;

  const fader = createElementFadeInOutControl(skinPicker);
  await fader.fadeIn(1.6);

  const pickedSkin = await skinPicker.onConfirmSelection.waitUntil();

  await fader.fadeOut(0.6);
  skinPicker.remove();

  unblockFF();

  return pickedSkin;
}
