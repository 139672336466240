import {
  GoogleAuthProvider,
  User,
  UserCredential,
  linkWithCredential,
  signInWithPopup,
} from 'firebase/auth';

import { auth } from '@/backend/firebase';
import { appContext } from '@/context/appContext';

// Function to sign in with Google
export async function signInWithGoogle() {
  // let previousUser = auth.currentUser || null;

  const provider = new GoogleAuthProvider();
  const newCred = await signInWithPopup(auth, provider);
  
  const newUser = newCred.user;
  console.log('🔑 [Google]', newUser);
  appContext.userData.authUserId = newUser.uid;
  appContext.userData.profile.setFieldValue('email', newUser.email);

  // linkAccounts(newCred, previousUser).catch(error => {
  //   console.warn('🔑 [Google] Failed to link accounts:', error);
  // });
}

async function linkAccounts(userCred: UserCredential, previousUser: User | null) {
  if (!previousUser) return;

  const credential = GoogleAuthProvider.credentialFromResult(userCred);
  if (!credential) return;

  console.log('🔑 [Google] Linking with previous user', previousUser);
  const newCred = await linkWithCredential(previousUser, credential);
  if (!newCred) return;
  
  const newUser = newCred.user;
  console.log('🔑 [Google] Successfully linked accounts', newUser);
  appContext.userData.authUserId = newUser.uid;
  appContext.userData.profile.setFieldValue('email', newUser.email);
}
