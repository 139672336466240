import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { appContext } from '@/context/appContext';
import { delay } from '@/util/delay';

export function createCharacterAnimationController(
  gltf: GLTF | undefined,
  addOnEnterFrameCallback: (callback: () => void) => void
) {
  if (!gltf) {
    console.warn('No gltf provided');
    return createFakeCharacterAnimationController();
  }

  if (!gltf.scene.parent) {
    throw new Error('GLTF scene has no parent');
  }

  ////

  const mixer = new THREE.AnimationMixer(gltf.scene);
  const clock = new THREE.Clock();
  const getTimeScale = () => appContext.animCtrl.timeScale.get();
  addOnEnterFrameCallback(() => mixer.update(clock.getDelta() * getTimeScale()));

  ////

  const api = { cycle: null as string | null };

  // console.log('🎞 gltf.animations', gltf.animations);
  const actions = {} as Record<string, THREE.AnimationAction>;
  for (const clip of gltf.animations) {
    const action = mixer.clipAction(clip);
    actions[clip.name] = action;
  }

  function playState(name: string, fadeDuration: number = 0.4, timeScale: number = 1) {
    if (name === api.cycle && activeAction?.getClip().name === name) {
      return;
    }

    return new Promise<void>(resolve => {
      try {
        const duration = fadeDuration;
        api.cycle = name;

        const action = fadeToAction(api.cycle, duration, timeScale);
        action.clampWhenFinished = false;
        action.loop = THREE.LoopRepeat;

        return delay(duration).then(() => resolve());
      } catch (error) {
        console.warn(error);
        // reject(error);
      }
    });
  }

  function playEmote(name: string, fadeDuration: number = 0.4, timeScale: number = 1) {
    return new Promise<void>(resolve => {
      try {
        const duration = fadeDuration;

        const action = fadeToAction(name, duration, timeScale);
        action.clampWhenFinished = true;
        action.loop = THREE.LoopOnce;

        mixer.addEventListener('finished', onFinished);

        function onFinished() {
          mixer.removeEventListener('finished', onFinished);
          restoreState();
          resolve();
        }
      } catch (error) {
        console.warn(error);
        // reject(error);
      }
    });
  }

  function restoreState(fadeDuration: number = 0.4) {
    return new Promise<void>((resolve, reject) => {
      try {
        const duration = fadeDuration;
        if (api.cycle) {
          fadeToAction(api.cycle, duration, 1);
        } else {
          console.warn('No state to restore');
        }
        return delay(duration).then(() => resolve());
      } catch (error) {
        reject(error);
      }
    });
  }

  let activeAction: THREE.AnimationAction | null = null;
  let previousAction: THREE.AnimationAction | null = null;
  function fadeToAction(name: string, duration: number, timeScale: number = 1) {
    previousAction = activeAction;
    activeAction = actions[name];

    if (!activeAction) {
      throw new Error(`Action "${name}" not found`);
    }

    if (previousAction !== activeAction) {
      if (duration > 0) {
        previousAction?.fadeOut(duration);
      } else {
        previousAction?.stop();
      }
    }

    if (duration > 0) {
      activeAction
        ?.reset()
        .setEffectiveTimeScale(timeScale)
        .setEffectiveWeight(1)
        .fadeIn(duration)
        .play();
    } else {
      activeAction?.reset().setEffectiveTimeScale(timeScale).setEffectiveWeight(1).play();
    }

    return activeAction;
  }

  return {
    setDefaultState: (name: string | null) => {
      api.cycle = name;
    },
    playAnimationState: playState,
    playAnimationEmote: playEmote,
    setVisible: (isVisible: boolean) => {
      gltf.scene.visible = isVisible;
    },
    getCurrentAction: () => activeAction,
    clearAllActions: () => {
      activeAction?.stop();
      previousAction?.stop();

      api.cycle = null;
      mixer.stopAllAction();
    },
  };
}

export type CharacterAnimationController = ReturnType<typeof createCharacterAnimationController>;

function createFakeCharacterAnimationController() {
  return {
    setDefaultState: () => {},
    playAnimationState: () => Promise.resolve(),
    playAnimationEmote: () => Promise.resolve(),
    setVisible: () => {},
    getCurrentAction: () => null,
    clearAllActions: () => {},
  };
}
