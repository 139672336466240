import { gsap } from 'gsap';

import { BackSide, FrontSide, Vector3 } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { MusicTracks } from '@/audio/music';
import { appContext } from '@/context/appContext';
import { ShiftContext } from '@/context/shiftContext';
import { createCharacterAnimationController } from '@/controllers/createCharacterAnimationController';
import { FakeShifterInput } from '@/debug/FakeShifterInput';
import { displayPlaceholderAnimationMessage } from '@/debug/html/displayPlaceholderAnimationMessage';
import { displayPlaceholderSplash } from '@/debug/html/displayPlaceholderSplash';
import { displayGetNextPresenceShiftSplash } from '@/html/displayGetNextPresenceShiftSplash';
import { displayShareSplash } from '@/html/displayShareSplash';
import { displayShareSuccessSplash } from '@/html/displayShareSuccessSplash';
import { SkinColors } from '@/html/gui/SkinColors';
import { displayBreathingAnimation } from '@/html/playback/displayBreathingAnimation';
import { displayOptionsPicker } from '@/html/playback/displayOptionsPicker';
import { displaySkinPicker } from '@/html/playback/displaySkinPicker';
import { defaultVars } from '@/misc/defaultVars';
import { ModelNodeNames } from '@/scene/constants/ModelNodeNames';
import { createModelPartMaterialsByNameProxy } from '@/scene/createModelPartMaterialsByNameProxy';
import { createModelPartObjectByNameProxy } from '@/scene/createModelPartObjectByNameProxy';
import { createSceneLightsPropsWrapper } from '@/scene/createSceneLightsPropsWrapper';
import { processStringWithVars } from '@/tools/processString';
import { createThreejsObjectTweenableWrapper } from '@/util/createThreejsObjectTweenableWrapper';
import { delay } from '@/util/delay';
import { nextFrame } from '@/util/nextFrame';
import { animationBook, animationBook2 } from './animationsBook';
import { createAnimatedSkinChanger } from './util/createAnimatedSkinChanger';
import { displaySigningSplash } from '@/html/displaySigningSplash';
import { checkUserSignedInNotAnonymously } from '@/backend/ensureUserSignedIn';

const { cycles, emotes } = animationBook;

function cloneGltf(gltf?: GLTF) {
  if (!gltf) throw new Error('No gltf provided');
  return {
    ...gltf,
    scene: gltf.scene.clone(),
  };
}

export function createTriggerResolverDictionary(shiftContext: ShiftContext) {
  const { appDiv, sfx } = appContext;

  const { penguin, dolphin1, dolphin2 } = shiftContext.gltfs! || {};
  const { addEnterFrameCallback } = shiftContext.sceneContext! || {};

  // const penguin = cloneGltf(shiftContext.gltfs?.penguin);
  // const dolphin1 = cloneGltf(shiftContext.gltfs?.dolphin1);
  // const dolphin2 = cloneGltf(shiftContext.gltfs?.dolphin2);

  const ctrlD1 = createCharacterAnimationController(dolphin1, addEnterFrameCallback);
  const ctrlD2 = createCharacterAnimationController(dolphin2, addEnterFrameCallback);

  const defaults = {
    dolphin1: {
      positionX: 200,
      positionY: 0,
      positionZ: 0,
      rotationX: 0.0,
      rotationY: 0.9,
      rotationZ: 0.0,
      scaleX: 1.2,
      scaleY: 1.2,
      scaleZ: 1.2,
    },
    dolphin2: {
      positionX: -140,
      positionY: -200,
      positionZ: -300,
      rotationX: 0.0,
      rotationY: 0.2,
      rotationZ: 0.0,
      scaleX: 1.0,
      scaleY: 1.0,
      scaleZ: 1.0,
    },
  };

  const after = (delaySeconds: number, callback: () => void) =>
    delay(delaySeconds).then(() => callback());

  const setCycleTimeScale = (ctrl: typeof ctrlD1, timeScale: number, fadeDuration: number) =>
    gsap.to(ctrl.getCurrentAction()!, { timeScale: timeScale, duration: fadeDuration });

  const dolphin1_SkinSetter = createAnimatedSkinChanger(shiftContext, dolphin1, 'pop');
  // const dolphin1_SkinSetter = createAnimatedSkinChanger(shiftContext, dolphin1, 'fade');

  const backgroundStuff = new Set<Promise<void>>();
  function addBackgroundStuff(promise: Promise<void>) {
    backgroundStuff.add(promise);
    promise.then(() => backgroundStuff.delete(promise));
  }
  function awaitAllBackgroundStuff() {
    return Promise.all(backgroundStuff);
  }

  const sceneLightsCtrl = createSceneLightsPropsWrapper(shiftContext.sceneContext!);
  const fakeLightsIntensity = {
    default: 0.22,
    presenceMode: 0.75,
    halfDark: 0.13,
    eyesClosed: 0.05,
  };

  function spawnManyBubbles(at: { x: number; y: number; z: number }) {
    const duration = 1.5;
    const ctrl = shiftContext.sceneContext?.homeCtrl?.bubblesCtrl;
    ctrl?.sprayBubbles(at, 30, 25, duration);
    return delay(duration);
  }

  const functions = {
    appear_TweenVersion: async () => {
      dolphin1.scene.visible = true;
      ctrlD1.playAnimationState(cycles.idle, 0, 1.5);
      // ctrlD1.playAnimationState(cycles.loop1r, 0, 1.5);
      // await characterCtrl.playAnimationEmote('Emerge and Point', 0);
      // await nextFrame();
      dolphin1.scene.rotation.y = 1.5;

      // await nextFrame();

      await gsap.from(dolphin1.scene.position, {
        x: -500,
        y: 500,
        z: -12000,
        duration: 6.5,
      });

      await setCycleTimeScale(ctrlD1, 0.9, 0.4);

      await gsap.to(dolphin1.scene.rotation, {
        delay: 0.1,
        y: 0.9,
        duration: 1.4,
        ease: 'power1.inOut',
      });

      await nextFrame();

      console.log(
        '🐬 Dolphin Transform:',
        dolphin1.scene.position.toArray(),
        dolphin1.scene.rotation.toArray(),
        dolphin1.scene.scale.toArray()
      );

      // characterCtrl.playAnimationState('Loop 2', 1);
    },
    appear_New: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);

      dolphin1.scene.rotation.set(0, 0.9, 0);
      dolphin1.scene.position.set(-200, 200, -1200);

      nextFrame().then(() => {
        dolphin1.scene.visible = true;
      });

      gsap.from(tweenable, {
        positionX: -1100,
        positionZ: -5000,
        duration: 1.5,
        ease: 'power2.out',
      });

      await ctrlD1.playAnimationEmote(emotes.enterSceneVer1, 0, 1.5);
      dolphin1.scene.visible = false;

      functions.resetDolphin1_hard();
      ctrlD1.playAnimationState(cycles.idle, 0);

      await delay(1.0);
      dolphin1.scene.visible = true;

      setCycleTimeScale(ctrlD1, 2, 0.5);

      await gsap.from(tweenable, {
        positionX: 600,
        positionY: 800,
        positionZ: -800,
        duration: 2.5,
      });
      await setCycleTimeScale(ctrlD1, 1, 1.2);
    },
    breathe1: async () => {
      displayBreathingAnimation(document.body, 1.6, 1.8);
      await sfx.playSoundBite('breathe1');
    },
    breathe2: async () => {
      displayBreathingAnimation(document.body, 1.9, 1.8);
      await sfx.playSoundBite('breathe2');
    },
    halfDarkenScene: async () => {
      const { homeCtrl } = shiftContext.sceneContext!;
      homeCtrl.fadeFakeLightsIntensityTo(fakeLightsIntensity.halfDark, 2);
      sceneLightsCtrl.fadeIntensityTo(0.2, 3.0);
    },
    resetLights: async () => {
      Promise.allSettled([
        sceneLightsCtrl.fadeIntensityTo(1.0, 3.0),
        shiftContext.sceneContext?.homeCtrl.fadeFakeLightsIntensityTo(
          fakeLightsIntensity.default,
          1
        ),
      ]);
    },

    enterPresenceMode: async () => {
      shiftContext.sceneContext?.homeCtrl.fadeFakeLightsIntensityTo(
        fakeLightsIntensity.presenceMode,
        2
      );

      gsap.to(dolphin1.scene.position, { z: 0, duration: 2.2, ease: 'power1.inOut' });

      ctrlD1.setDefaultState(cycles.presenceMode);
      await ctrlD1.playAnimationEmote(emotes.enterPresenceMode);
    },
    exitPresenceMode: async () => {
      functions.resetLights();
      ctrlD1.playAnimationState(cycles.idle, 1.5);
    },

    penguinSpawns: async () => {
      penguin.scene.visible = true;
      penguin.scene.position.set(-190, -180, -0);
      penguin.scene.rotation.y = 1;

      await gsap.from(penguin.scene.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.4,
        ease: 'power2.out',
      });
    },
    penguinFarts: async (numberOfFarts = 1, delayBeforeTurnBack = 0) => {
      async function puffSomeFart() {
        const { fart } = shiftContext.gltfs!;
        if (!fart) return;

        fart.scene.visible = true;
        fart.scene.position.set(-160, -180, 180);
        fart.scene.scale.set(2.5, 2, 2);
        fart.scene.rotation.y = Math.PI;

        const tweenable = createThreejsObjectTweenableWrapper(fart.scene);
        const scaleTween = gsap.to(fart.scene.scale, {
          overwrite: 'auto',
          x: 3.7,
          y: 1.7,
          duration: 12,
          ease: 'power.out',
        });

        tweenable.opacity = 0.0;
        const alphaTween = gsap
          .to(tweenable, {
            overwrite: 'auto',
            opacity: 1.0,
            duration: 6,
            ease: 'power2.out',
          })
          .then(() =>
            gsap.to(tweenable, {
              overwrite: 'auto',
              opacity: 0.0,
              duration: 6,
              ease: 'power2.in',
            })
          );

        await Promise.all([scaleTween, alphaTween]);

        fart.scene.visible = false;
      }

      async function penguinTurnAround() {
        const tweenable = createThreejsObjectTweenableWrapper(penguin.scene);
        await gsap.to(tweenable, {
          rotationY: -1.8,
          duration: 0.2,
          ease: 'power2.inOut',
        });
      }

      async function penguinChargeFart() {
        const tweenable = createThreejsObjectTweenableWrapper(penguin.scene);
        await gsap.to(tweenable, {
          scaleY: 0.8,
          duration: 0.2,
          ease: 'power2.in',
        });
      }

      async function penguinReleaseFart() {
        const tweenable = createThreejsObjectTweenableWrapper(penguin.scene);
        await gsap.to(tweenable, {
          scaleY: 1.0,
          duration: 1.1,
          ease: 'elastic.out',
        });
      }

      async function penguinTurnBack() {
        const tweenable = createThreejsObjectTweenableWrapper(penguin.scene);
        await gsap.to(tweenable, {
          rotationY: 1,
          duration: 0.2,
          ease: 'power2.inOut',
        });
      }

      let puffedAlready = false;

      await penguinTurnAround();
      for (let i = 0; i < +numberOfFarts; i++) {
        await penguinChargeFart();
        sfx.playSoundBite('fart');
        await penguinReleaseFart();

        if (!puffedAlready) {
          puffSomeFart();
          puffedAlready = true;
        }
      }
      await delay(+delayBeforeTurnBack);
      penguinTurnBack();
    },

    encounterPenguin: async () => {
      shiftContext.sceneContext?.homeCtrl.fadeFakeLightsIntensityTo(fakeLightsIntensity.default, 1);

      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      gsap.to(tweenable, {
        positionY: defaults.dolphin1.positionY - 100,
        rotationY: 0,
        duration: 2.0,
        ease: 'power2.inOut',
      });

      ctrlD1.setDefaultState(cycles.idle);
      await ctrlD1.playAnimationEmote(emotes.encounterPenguin, 1.0);
      await functions.resetDolphin1(1.5);
    },

    encounterPenguin2: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      gsap.to(tweenable, {
        positionY: defaults.dolphin1.positionY - 100,
        rotationY: 0,
        duration: 2.0,
        ease: 'power2.inOut',
      });

      ctrlD1.setDefaultState(cycles.idle);
      await ctrlD1.playAnimationEmote(emotes.encounterPenguin2, 1.0);
      await functions.resetDolphin1(1.5);
    },

    approachShifter: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      await functions.resetDolphin1(0.5);

      setCycleTimeScale(ctrlD1, 1.9, 0.3);

      await gsap.to(tweenable, {
        positionZ: defaults.dolphin1.positionZ + 500,
        duration: 1.2,
        ease: 'power2.inOut',
      });

      await setCycleTimeScale(ctrlD1, 1, 0.2);
    },
    unapproachShifter: async () => {
      setCycleTimeScale(ctrlD1, 1.7, 0.3);

      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      await gsap.to(tweenable, {
        ...defaults.dolphin1,
        rotationX: -0.4,
        duration: 2,
        ease: 'power2.inOut',
      });
      setCycleTimeScale(ctrlD1, 1.0, 0.2);
      await functions.resetDolphin1(0.6);
    },

    hidePenguin: async () => {
      await gsap.to(penguin.scene.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.5,
        ease: 'back.in(1.7)',
        onComplete: () => {
          penguin.scene.visible = false;
          penguin.scene.scale.set(1, 1, 1);
        },
      });
    },
    dolphiniaSpawns: async () => {
      dolphin2.scene.visible = true;
      dolphin2.scene.position.set(
        defaults.dolphin2.positionX,
        defaults.dolphin2.positionY,
        defaults.dolphin2.positionZ
      );

      const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
      tweenable.rotationY = 0.6;

      ctrlD2.playAnimationState(animationBook2.DolphiniaIdle, 0);
      await gsap.fromTo(
        tweenable,
        {
          scaleAll: 0.3,
        },
        {
          scaleAll: 1.0,
          duration: 0.5,
        }
      );

      spawnManyBubbles({ x: -280, y: 350, z: -200 });
    },
    dolphiniaSpawns2: async () => {
      dolphin2.scene.position.set(-140, -200, -300);
      dolphin2.scene.visible = true;
      dolphin2.scene.rotation.y = 0.6;
      dolphin2.scene.scale.set(1, 1, 1);

      await nextFrame();

      ctrlD2.playAnimationState(animationBook2.DolphiniaIdle, 0);
      await ctrlD2.playAnimationEmote(animationBook2.DolphiniaSummon, 0);
    },
    showDolphinia: async () => {
      dolphin2.scene.visible = false;
      dolphin2.scene.position.set(-180, -100, -300);

      const awaitables = [] as { then: Function }[];

      ctrlD2.setDefaultState(animationBook2.DolphiniaIdle);
      const clip = ctrlD2.playAnimationEmote(animationBook2.DolphiniaSummersaultShort, 0, 1);
      awaitables.push(clip);

      await nextFrame();
      dolphin2.scene.visible = true;

      const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
      tweenable.rotationY = 0.6;

      dolphin2.scene.visible = true;
      awaitables.push(
        gsap.fromTo(
          tweenable,
          {
            scaleAll: 0.3,
          },
          {
            scaleAll: 1.0,
          }
        )
      );

      awaitables.push(
        gsap.fromTo(
          tweenable,
          {
            positionX: -180,
            positionY: -100,
          },
          {
            positionX: -140,
            positionY: -170,
            duration: 7.0,
            ease: 'power2.inOut',
          }
        )
      );

      delay(1.5).then(() => {
        ctrlD1.playAnimationState(cycles.idle, 1.8);
        functions.resetDolphin1(1.4);
      });

      await Promise.allSettled(awaitables);
    },
    exitDolphinia: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
      gsap.to(tweenable, { rotationX: -0.05, rotationY: 0.2, duration: 0.3 });
      ctrlD2.setDefaultState(null);
      await ctrlD2.playAnimationEmote(animationBook2.DolphiniaExit2, 0.7, 0.8);
    },
    encounterDolphinia: async () => {
      shiftContext.sceneContext?.homeCtrl.fadeFakeLightsIntensityTo(fakeLightsIntensity.default, 1);

      ctrlD1.setDefaultState(cycles.talking);
      await ctrlD1.playAnimationEmote(emotes.laugh, 1.0);
      gsap.to(dolphin1.scene.rotation, { y: 0, duration: 0.5 });
    },
    d2_swim: async () => {
      // ctrlD2.setDefaultState(animationBook2.DolphiniaIdle);
      // await ctrlD2.playAnimationEmote(animationBook2.DolphiniaSwim, 0.1, 1.0);

      const awaitables = [] as { then: Function }[];

      ctrlD2.setDefaultState(animationBook2.DolphiniaIdle);
      const clip = ctrlD2.playAnimationEmote(animationBook2.DolphiniaSwim, 0.6, 1.1);
      awaitables.push(clip);

      const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
      await gsap.to(tweenable, {
        positionX: -180,
        positionY: -100,
        rotationY: 0.6,
        duration: 0.6,
        ease: 'power2.inOut',
      });

      awaitables.push(
        gsap.fromTo(
          tweenable,
          {
            positionX: -180,
            positionY: -100,
          },
          {
            positionX: -140,
            positionY: -170,
            duration: 7.0,
            ease: 'power2.inOut',
          }
        )
      );

      await Promise.allSettled(awaitables);
    },

    appear: async () => {
      await delay(1.5);

      dolphin1.scene.visible = true;

      ctrlD1.setDefaultState(cycles.idle);

      gsap.from(dolphin1.scene.position, { x: -2000, z: -2000, duration: 1.8 });
      await ctrlD1.playAnimationEmote(emotes.enterSceneVer2, 0, 0.8);

      await gsap.to(dolphin1.scene.rotation, {
        delay: 0.1,
        y: 0.9,
        duration: 1.4,
        ease: 'power1.inOut',
      });

      // await ctrlD1.playAnimationState(cycles.idle, 0, 1.5);

      // await characterCtrl.playAnimationEmote('Emerge and Point', 0);
      // await nextFrame();
    },
    swimOnBack: async () => {
      ctrlD1.setDefaultState(cycles.talking);

      // gsap.from(dolphin1.scene.position, { x: -2000, z: -2000, duration: 1.8 });
      await ctrlD1.playAnimationEmote(emotes.swimOnBack, 2.0, 1.1);
    },
    laugh: async () => {
      await ctrlD1.playAnimationEmote(emotes.laugh, 2.0, 1.1);
    },

    cycleSkinColors: async () => {
      const delayBetweenSkins = 1.2;

      await delay(delayBetweenSkins);
      await dolphin1_SkinSetter.setSkinColorAnimatedly(SkinColors.Pink);
      await delay(delayBetweenSkins);
      await dolphin1_SkinSetter.setSkinColorAnimatedly(SkinColors.Green);
      await delay(delayBetweenSkins);
      await dolphin1_SkinSetter.setSkinColorAnimatedly(SkinColors.DeepBlue);
      await delay(delayBetweenSkins);
      await dolphin1_SkinSetter.setSkinColorAnimatedly(SkinColors.Default);
    },

    turnToPenguin: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      await gsap.to(tweenable, {
        rotationY: 5,
        duration: 0.2,
        ease: 'power2.inOut',
      });
    },

    theBuzzingSpecial: async (restartMusicAfterSeconds: number = 28) => {
      function fadeLights() {
        sceneLightsCtrl.fadeIntensityTo(0.2, 3.0);
        homeCtrl.fadeFakeLightsIntensityTo(fakeLightsIntensity.eyesClosed, 3);

        return () => {
          sceneLightsCtrl.fadeIntensityTo(1.0, 3.0);
          homeCtrl.fadeFakeLightsIntensityTo(fakeLightsIntensity.default, 3);
        };
      }

      function closeEyes() {
        parts.eyeL.visible = false;
        parts.eyeR.visible = false;

        return () => {
          parts.eyeL.visible = true;
          parts.eyeR.visible = true;
        };
      }

      // function fadeMusic() {
      //   console.log('🎤 🎤 🎤 🎤 Muting music for buzzing-special 🎤 🎤 🎤 🎤 ');
      //   // gsap.to(appContext.music, { volume: 0, duration: 6, ease: 'power1.out' });
      //   // return () => gsap.to(appContext.music, { volume: DEFAULT_MUSIC_VOLUME, duration: 6, ease: 'power2.out' });
      //   const REASON_TO_MUTE = 'shift-sequence-in-progress';
      //   return appContext.music.reasonsToMute.add(REASON_TO_MUTE);
      // }

      const parts = createModelPartObjectByNameProxy(dolphin1.scene, ModelNodeNames.Dolphin1);

      const { homeCtrl } = shiftContext.sceneContext!;

      const unfadeLights = fadeLights();

      ctrlD1.setDefaultState(cycles.presenceMode);
      await ctrlD1.playAnimationEmote(emotes.enterPresenceMode, 1, 15);

      const uncloseEyes = closeEyes();

      await setCycleTimeScale(ctrlD1, 0.5, 2);

      {
        const speechClipExt = ['ac3', 'mp3', 'm4a', 'ogg'];
        const speechClipUrl = 'voice-special/ps1-shift-sequence-loud-part-1';
        const speechAwaitable = appContext.sfx.playSoundBiteTrackable(speechClipUrl, speechClipExt);
        speechAwaitable.soundInstance?.volume(0.7);

        speechAwaitable.callAtTime?.(restartMusicAfterSeconds, () => {
          appContext.music.playOnlyOnce(MusicTracks.Main);
        });

        Object.assign(window, { skipTo4min: () => speechAwaitable.skipTo?.(230) });
        await speechAwaitable;
      }

      // const unfadeMusic = fadeMusic();

      {
        const speechClipExt = ['ac3', 'mp3', 'm4a', 'ogg'];
        const speechClipUrl = 'voice-special/ps1-shift-sequence-loud-part-2';
        const speechAwaitable = appContext.sfx.playSoundBiteTrackable(speechClipUrl, speechClipExt);
        speechAwaitable.soundInstance?.volume(0.7);
        await speechAwaitable;
      }

      appContext.music.playTrack(MusicTracks.Main);

      // unfadeMusic();

      unfadeLights();

      uncloseEyes();

      await Promise.allSettled([
        setCycleTimeScale(ctrlD1, 0.5, 2),
        ctrlD1.playAnimationState(cycles.idle, 2, 1.5),
      ]);
    },

    turnInsideOut: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      const materials = createModelPartMaterialsByNameProxy(
        dolphin1.scene,
        ModelNodeNames.Dolphin1
      );

      async function changePolygonsDirection(backSide: boolean) {
        await gsap.to(tweenable, {
          scaleAll: 0,
          rotateX: 1.5,
          duration: 0.3,
          ease: 'power2.in',
        });

        materials.body.side = backSide ? BackSide : FrontSide;

        await gsap.to(tweenable, {
          scaleAll: defaults.dolphin1.scaleX,
          duration: 1.1,
          ease: 'elastic.out',
        });
      }

      await delay(1);

      await changePolygonsDirection(true);
      await delay(0.5);
      await ctrlD1.playAnimationEmote(emotes.laugh, 0.2, 1.5);
      await delay(0.8);
      await changePolygonsDirection(false);

      await delay(1);
    },

    setCycleToIdle: async () => {
      await ctrlD1.playAnimationState(cycles.idle, 0.8, 1.0);
    },
    setCycleToTalking: async () => {
      await ctrlD1.playAnimationState(cycles.talking, 0.8, 1.0);
    },
    setCycleToWaiting: async () => {
      await ctrlD1.playAnimationState(cycles.waiting, 0.8, 1.0);
    },
    setCycleToLoop1: async () => {
      await ctrlD1.playAnimationState(cycles.loop1, 0.8, 1.0);
    },

    emote_Nod: async () => {
      await ctrlD1.playAnimationEmote(emotes.nod, 0.2, 1.2);
    },
    emote_Talk: async () => {
      await ctrlD1.playAnimationEmote(emotes.talk, 0.2, 1.2);
    },

    suspense: async () => {
      await setCycleTimeScale(ctrlD1, 0.3, 0.7);
      await delay(1.1);
      setCycleTimeScale(ctrlD1, 1.0, 0.5);
    },

    think: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      await gsap.to(tweenable, {
        rotationY: -0.2,
        duration: 0.9,
        ease: 'power2.inOut',
      });
      await delay(1.6);
      await gsap.to(tweenable, {
        rotationY: defaults.dolphin1.rotationY,
        duration: 0.5,
        ease: 'back.in',
      });
    },

    turnLeft: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      await gsap.to(tweenable, {
        rotationY: -0.2,
        duration: 0.9,
        ease: 'power2.inOut',
      });
    },

    turnForward: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      await gsap.to(tweenable, {
        rotationY: defaults.dolphin1.rotationY,
        duration: 0.9,
        ease: 'power2.inOut',
      });
    },

    piruette: async () => {
      ctrlD1.setDefaultState(cycles.idle);
      await ctrlD1.playAnimationEmote(emotes.swimUp, 0.1, 1.0);
    },

    d2_nod: async () => {
      await ctrlD2.playAnimationEmote(animationBook2.Nodding, 0.25, 1.3);
    },

    releaseBubbles: async () => {
      const jaw = dolphin1.scene.getObjectByName('Dolphin_rig_v003Jaw_M');
      if (!jaw) throw new Error('No jaw found');

      const at = jaw?.getWorldPosition(new Vector3());
      at.y = -at.y;

      const duration = 1.5;
      const ctrl = shiftContext.sceneContext?.homeCtrl?.bubblesCtrl;
      ctrl?.sprayBubbles(at, 30, 25, duration);

      await delay(duration);
    },

    resetDolphin1: async (duration: string | number = 0.4) => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
      await gsap.to(tweenable, {
        ...defaults.dolphin1,
        duration: +duration,
        ease: 'power2.inOut',
      });
    },

    resetDolphin1_hard: async (visible?: unknown) => {
      if (visible !== undefined) {
        dolphin1.scene.visible = Boolean(visible);
      }

      dolphin1.scene.position.set(
        defaults.dolphin1.positionX,
        defaults.dolphin1.positionY,
        defaults.dolphin1.positionZ
      );
      dolphin1.scene.rotation.set(
        defaults.dolphin1.rotationX,
        defaults.dolphin1.rotationY,
        defaults.dolphin1.rotationZ,
        'XYZ'
      );
      dolphin1.scene.scale.set(
        defaults.dolphin1.scaleX,
        defaults.dolphin1.scaleY,
        defaults.dolphin1.scaleZ
      );
    },

    resetDolphin2: async (duration: string | number = 0.4) => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
      await gsap.to(tweenable, {
        ...defaults.dolphin2,
        duration: +duration,
        ease: 'power2.inOut',
      });
    },

    /////

    displaySkinPicker: async () => {
      if (shiftContext.playerCtrl.reasonsToSkipWaitingForUserInput.hasAny()) {
        shiftContext.userAnswers.setValue('presenceSkin', null);
        return;
      }

      const pickedSkin = (await displaySkinPicker(
        shiftContext.playerCtrl.elements.containerForShifterInput,
        shiftContext
      )) as string;

      console.log('🎨 Picked skin:', pickedSkin);

      shiftContext.userAnswers.setValue('presenceSkin', pickedSkin?.toLowerCase());
    },

    displayOptionsPicker: async (answerKey: string, ...options: string[]) => {
      const buttonDescriptors = options.map(option => {
        const [value, label] = option.split('|');
        return { value, label };
      });

      const { playerCtrl, userAnswers } = shiftContext;
      playerCtrl.onUserInputRequested.emit(answerKey);

      async function performOptionPicking() {
        if (shiftContext.playerCtrl.reasonsToSkipWaitingForUserInput.hasAny()) {
          return FakeShifterInput.getFakeOptionPick(answerKey, buttonDescriptors);
        }

        return await displayOptionsPicker(
          shiftContext.playerCtrl.elements.containerForShifterInput,
          buttonDescriptors
        );
      }

      const pickedOption = await performOptionPicking();

      userAnswers.setValue(answerKey, pickedOption);
      playerCtrl.onUserInputReceived.emit(answerKey, pickedOption);

      //// TODO: Unconvolute this
      const optionLabel = buttonDescriptors.find(d => d.value === pickedOption)?.label;
      await playerCtrl.submitUserAnswer(answerKey, optionLabel ?? '');

      console.log('🎨 Picked option:', pickedOption);
    },

    displayShareSplash: async () => {
      appContext.events.displaySplashScreen.emit('share');

      const parentElement = appContext.appDiv;

      const storedShifterName = await appContext.userData.profile.getFieldValue('name');
      const shifterName = storedShifterName ?? defaultVars.shifter_name;
      const shares = await displayShareSplash(parentElement, shifterName);

      if (shares) {
        shiftContext.userAnswers.setValue('sharedPlatforms', shares?.join(','));
      } else {
        shiftContext.userAnswers.setValue('sharedPlatforms', null);
      }
    },

    displayShareFinishedSplash: async () => {
      appContext.events.displaySplashScreen.emit('after-share-thank-you');

      const parentElement = appContext.appDiv;

      const storedShifterName = await appContext.userData.profile.getFieldValue('name');
      const shifterName = storedShifterName ?? defaultVars.shifter_name;
      await displayShareSuccessSplash(parentElement, shifterName);
    },

    displaySignInSplash: async () => {
      const isLoggedIn = await checkUserSignedInNotAnonymously();
      if (isLoggedIn) return;

      const parentElement = appContext.appDiv;
      await displaySigningSplash(parentElement);
    },

    displayEmotionListSplash: async (answerKey: string) => {
      const hyperlinks = appContext.emotionsProcessor
        .getEmotionsList()
        .map(emotion => `<a>${emotion}</a>`)
        .join(', ');

      const content = `
# Choose an emotion from the list below

${hyperlinks}
      `;
      const pickedEmotion = await displayPlaceholderSplash(appDiv, content, true);
      console.log('👆 User picked emotion:', pickedEmotion);

      if (answerKey && pickedEmotion) {
        const { userAnswers, playerCtrl } = shiftContext;

        userAnswers.setValue(answerKey, pickedEmotion);

        await playerCtrl.submitUserAnswer(answerKey, pickedEmotion);
      }
    },

    displaySubForPS2Splash: async () => {
      appContext.events.displaySplashScreen.emit('sub-for-ps2');

      const parentElement = appContext.appDiv;

      const storedShifterName = await appContext.userData.profile.getFieldValue('name');
      const shifterName = storedShifterName ?? defaultVars.shifter_name;
      await displayGetNextPresenceShiftSplash(parentElement, shifterName);
    },

    finishBackgrounStuff: async () => {
      await awaitAllBackgroundStuff();
    },

    exitIntro: async () => {
      async function exitDolphin1() {
        const tweenable = createThreejsObjectTweenableWrapper(dolphin1.scene);
        gsap.to(tweenable, { rotationY: 0.0, duration: 0.8 });
        await ctrlD1.playAnimationEmote(emotes.exitSceneVer2);

        dolphin1.scene.visible = false;
        functions.resetDolphin1_hard();
      }

      async function exitDolphin2() {
        await delay(0.4);
        await functions.exitDolphinia();
      }

      await Promise.all([exitDolphin1(), exitDolphin2()]);
    },
    exitPS1: async () => {
      async function exitDolphin1() {
        await delay(2.4);
        await ctrlD1.playAnimationEmote(emotes.exitSceneVer1, 1.5);
        dolphin1.scene.visible = false;
      }

      async function exitDolphin2() {
        ctrlD2.setDefaultState(null);
        const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
        gsap.to(tweenable, { rotationX: -0.05, rotationY: -0.07, duration: 1.9 });
        await ctrlD2.playAnimationEmote(animationBook2.DolphiniaExit2, 0.7, 0.8);
      }

      await Promise.all([exitDolphin1(), exitDolphin2()]);

      // const clip1 = ctrlD1
      //   .playAnimationEmote(emotes.exitSceneVer1, 2.5)
      //   .then(() => (dolphin1.scene.visible = false));
      // await clip1;
    },
    approachShifterD2: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
      await functions.resetDolphin1(0.5);

      setCycleTimeScale(ctrlD2, 1.9, 0.3);

      const originalPositionZ = tweenable.positionZ;
      await gsap.to(tweenable, {
        positionZ: originalPositionZ + 500,
        duration: 1.2,
        ease: 'power2.inOut',
      });

      await setCycleTimeScale(ctrlD2, 1, 0.2);
    },
    unapproachShifterD2: async () => {
      const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
      await functions.resetDolphin1(0.5);

      setCycleTimeScale(ctrlD2, 1.9, 0.3);

      const originalPositionZ = tweenable.positionZ;
      await gsap.to(tweenable, {
        positionZ: originalPositionZ - 500,
        duration: 1.2,
        ease: 'power2.inOut',
      });

      await setCycleTimeScale(ctrlD2, 1, 0.2);
    },

    ///// DEBUG

    __appearQuick: async () => {
      functions.resetDolphin1_hard();
      dolphin1.scene.visible = true;
      ctrlD1.playAnimationState(cycles.idle, 0);
    },

    __testAnimations: async () => {
      ctrlD1.setDefaultState(cycles.idle);
      await ctrlD1.playAnimationEmote(emotes.swimUp, 0.1, 1.0);
      await delay(2);

      await ctrlD1.playAnimationEmote(emotes.swimUp, 0.1, 1.0);
      await ctrlD1.playAnimationEmote(emotes.swimUp, 0.1, 1.0);
      return;
      await ctrlD2.playAnimationState(animationBook2.Waiting, 1.0);
      await ctrlD2.playAnimationEmote(animationBook2.Nodding, 0.2);

      while (true) {
        dolphin1.scene.visible = true;
        // dolphin2.scene.visible = true;

        await functions.resetDolphin1(0.3);

        const all = animationBook.getAll();
        const animationsToCompare = [
          all.IdleCycle,
          all.Loop2,
          all.Loop2Ver2,
          all.Loop1Ready,
          all.Nodding,
        ];

        while (true) {
          for (const animation of animationsToCompare) {
            await delay(0.5);
            await ctrlD1.playAnimationEmote(animation, 0.25);
          }
        }

        ///// EXIT 1
        {
          await ctrlD1.playAnimationEmote(all.Nodding, 0);
          await ctrlD1.playAnimationEmote(all.Talking, 0);
          await ctrlD1.playAnimationEmote(all.LaughCycle, 0);
          await ctrlD1.playAnimationEmote(all.EncounterPenguin, 0);
          await ctrlD1.playAnimationEmote(all.PenguineEncounterRootAdjustedMergedExporting, 0);
        }

        {
          //// Reset Dolphin 2
          const tweenable = createThreejsObjectTweenableWrapper(dolphin2.scene);
          await gsap.to(tweenable, {
            ...defaults.dolphin2,
            duration: 0.3,
            ease: 'power2.inOut',
          });
          await functions.showDolphinia();
          await functions.encounterDolphinia();
        }

        // await ctrlD1.playAnimationEmote(all.PresenceReturns, 0);
        // await ctrlD1.playAnimationEmote(all.EmergeAndPoint, 0);
        // await ctrlD1.playAnimationEmote(all.PresenceEmerges, 0);
        // await ctrlD1.playAnimationEmote(emotes.encounterPenguin, 0);
        // await ctrlD1.playAnimationEmote(all.PenguineEncounter, 0);
      }
    },

    __displayPlaceholderSplash: async (content: string) => {
      await displayPlaceholderSplash(
        appDiv,
        content ? processStringWithVars(content, shiftContext, '—', false) : undefined
      );
    },

    __indicatePlaceholderAnimation: async (text: string, timeOnScreen_?: string) => {
      const timeOnScreen = timeOnScreen_ === undefined ? 3.2 : +timeOnScreen_;
      await displayPlaceholderAnimationMessage(appDiv, text, timeOnScreen);
    },
  } as TriggerResolverDictionary;

  return {
    resolveTrigger(triggerKey: string, triggerArgs: (string | number)[], inTheBackground: boolean) {
      const triggerFunction = functions[triggerKey];

      if (!triggerFunction) {
        console.error('🐬 Trigger not found:', triggerKey);
        return Promise.resolve();
      }

      const triggerAwaitable = triggerFunction(...triggerArgs);

      if (inTheBackground) {
        addBackgroundStuff(triggerAwaitable);
        return Promise.resolve();
      } else {
        return triggerAwaitable;
      }
    },
    dispose() {
      ctrlD1.clearAllActions();
      ctrlD2.clearAllActions();

      dolphin1.scene.visible = false;
      dolphin2.scene.visible = false;
      penguin.scene.visible = false;
    },
  };
}

export type TriggerResolverDictionary = Record<
  string,
  (...args: (string | number)[]) => Promise<void>
>;
