export module FakeShifterInput {
  export function getFakeWrittenInput(answerKey: string) {
    return answerKey;
  }

  export function getFakeOptionPick(
    _answerKey: string,
    options: { value: string; label: string }[]
  ) {
    return options[0].value;
  }
}

