import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('boomerang-video-background')
export class BoomerangVideoBackground extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;

  render() {
    return html`
      <video autoplay loop muted playsinline>
        <source src="/video/boomerang.webm" type="video/webm" />
        <source src="/video/boomerang.mp4" type="video/mp4" />
      </video>
    `;
  }
}
