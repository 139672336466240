////////////////////////////////////////////////////////////////////////////////
//// Utility functions
////////////////////////////////////////////////////////////////////////////////

type GLTF = import('three/examples/jsm/loaders/GLTFLoader.js').GLTF;

export function produceAnimationNamesMap(obj: GLTF) {
  const result = {} as Record<string, string>;

  for (const animation of obj.animations) {
    const name = animation.name;
    const camelCaseName = toCamelCase(name);
    result[camelCaseName] = name;
  }

  return result;
}
Object.assign(window, { produceAnimationNamesMap });

function toCamelCase(str: string): string {
  str = str.replace(/^[A-Z]\d+_/, '');

  // Remove all non-alphanumeric and non-space characters
  let alphanumericStr = str.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/ +/g, ' ');

  // Convert to camel case
  let camelCaseStr = alphanumericStr
    .split(' ')
    .map((word, index) =>
      index === 0 ? word : word[0] === undefined ? '' : word[0].toUpperCase() + word.slice(1)
    )
    .join('');

  return camelCaseStr;
}
