type ObjectType<T = any> = { [K in keyof T]: T[K] extends object ? DeepMerge<T[K]> : T[K] };

type DeepMerge<T = any> = T extends (infer U)[]
  ? U[]
  : T extends object
  ? ObjectType<T>
  : T;

export function deepMerge<T extends object, S extends object>(target: T, source: S): DeepMerge<T & S> {
  const isObject = (obj: any): obj is object => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    throw new Error('Both target and source must be objects');
  }

  const result: any = { ...target };

  Object.keys(source).forEach((key: any) => {
    const keyTyped = key as keyof T & keyof S;
    const targetValue = target[keyTyped];
    const sourceValue = source[keyTyped];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      result[keyTyped] = [...sourceValue]; // Replace the old array with the new one
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      result[keyTyped] = deepMerge({ ...targetValue }, sourceValue);
    } else {
      result[keyTyped] = sourceValue;
    }
  });

  return result as DeepMerge<S & T>;
}
