import gsap from 'gsap';

export function createElementFadeInOutControl(element: HTMLElement) {
  return {
    fadeIn(duration: number) {
      return gsap.fromTo(element, { opacity: 0 }, { opacity: 1, duration });
    },
    fadeOut(duration: number) {
      return gsap.fromTo(element, { opacity: 1 }, { opacity: 0, duration });
    },
    fadeOutAndRemove(duration: number) {
      return this.fadeOut(duration).then(() => element.remove());
    },
  };
}
