import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('platform-login-button')
export class PlatformLoginButton extends LitElement {
  static styles = css`
    :host {
      background-color: #0008;
      padding: 0.5rem 3rem;
      border-radius: 0.7em;
      color: #fff;
      cursor: pointer;
      pointer-events: all;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      gap: 0.5rem;

      box-shadow:
        0 0 10px #0ff0 inset,
        0 0 40px #0ff0;
      transition-duration: 0.65s;
      transition-property: box-shadow, background-color;

      position: relative;

      font-size: 20px;
    }

    :host(:hover) {
      background-color: #0339;
      box-shadow:
        0 0 10px cyan inset,
        0 0 80px #0ff9;
      transition-duration: 0.15s;
    }

    :host([disabled]) {
      pointer-events: none;
    }

    :host([disabled])::after {
      content: 'coming soon..';
      font-family: sans-serif;

      color: #fff9;
      text-align: center;
      font-size: 0.8rem;
      text-style: italic;

      position: absolute;
      top: 100%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -5%);
    }

    :host([disabled]) img.icon,
    :host([disabled]) span.label {
      opacity: 0.3;
    }
  `;

  @property({ type: String }) platform = '';
  @property({ type: String }) icon = '';
  @property({ type: Boolean }) disabled = false;

  render() {
    return html`
      <img
        class="icon"
        src="${this.icon}"
        alt="${this.platform} Logo"
        ?disabled="${this.disabled}"
      />
      <span class="label" ?disabled="${this.disabled}">${this.platform}</span>
    `;
  }
}
