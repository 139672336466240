import { gsap } from 'gsap';

import { LitElement, html, css } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

declare global {
  interface HTMLElementTagNameMap {
    'chat-bubble': ChatBubble;
  }
}

@customElement('chat-bubble')
export class ChatBubble extends LitElement {
  @property() bubbleContent?: string;
  @property() bubbleStyle?: string;
  @property() bubbleAuthor?: string;
  @property() rightAlign?: boolean;

  public readonly onDisposeHandlers = [] as (() => void)[];

  @query('.bubble-content')
  public readonly bubbleContentDiv!: HTMLDivElement;

  @queryAll('.word')
  public readonly words!: NodeListOf<HTMLSpanElement>;

  static styles = css`
    :host {
      display: block;
      width: 100%;

      position: relative;

      --soft-corner-radius: 1.2em;
      --hard-corner-radius: 0.3em;

      --soft-margin: 0.5em;
      --hard-margin: 0.1em;

      pointer-events: none;
    }

    * {
      margin: 0;
    }

    .bubble-row {
      display: flex;
      flex-direction: column;
      padding-right: 1vmin;
    }

    .bubble-row.left-align {
      align-items: flex-start;
      padding-right: 4em;
    }

    .bubble-row.right-align {
      align-items: flex-end;
      padding-left: 4em;
    }

    .bubble-content.default-bubble {
      padding: 0.4em 1.2em;
      border-radius: var(--soft-corner-radius);
      border: 2px solid rgba(255, 255, 255, 0.2);
      box-shadow:
        0 5px 10px rgba(0, 0, 0, 0.5),
        inset 0 0 5px rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(10px);
      color: white;
      font-family: Arial, sans-serif;
      background: linear-gradient(45deg, rgba(10, 20, 60, 0.4), rgba(10, 60, 40, 0.4));
      font-size: calc(0.7em + 10px);

      margin: var(--soft-margin);
    }

    .bubble-content.default-bubble.by-shifter {
      background: linear-gradient(45deg, rgba(10, 90, 60, 0.5), rgba(90, 120, 30, 0.4));
    }

    .bubble-content.clear-bubble {
      color: #fff;
      font-size: 48px;
      font-size: 24px;
      text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000,
        0 0 20px #000;
    }

    span {
      display: inline-block;
    }

    .bubble-content.hard-corner-tl {
      border-top-left-radius: var(--hard-corner-radius);
      margin-top: var(--hard-margin);
    }

    .bubble-content.hard-corner-tr {
      border-top-right-radius: var(--hard-corner-radius);
      margin-top: var(--hard-margin);
    }

    .bubble-content.hard-corner-bl {
      border-bottom-left-radius: var(--hard-corner-radius);
      margin-bottom: var(--hard-margin);
    }

    .bubble-content.hard-corner-br {
      border-bottom-right-radius: var(--hard-corner-radius);
      margin-bottom: var(--hard-margin);
    }
  `;

  constructor() {
    super();

    this.bubbleContent = '';
    this.bubbleStyle = '';
    this.bubbleAuthor = '';
    this.rightAlign = false;
  }

  render() {
    const contentWithWordsReplaced = this.bubbleContent?.replace(
      /\b([\w\!\.\,\?]+)(?![^<]*>)/g,
      '<span class="word">$1</span>'
    );
    const contentHtml = unsafeHTML(contentWithWordsReplaced);

    return html`
      <div
        class="bubble-row ${this.bubbleStyle} by-${this.bubbleAuthor} ${this.rightAlign
          ? 'right-align'
          : 'left-align'}"
      >
        <div class="bubble-content ${this.bubbleStyle} by-${this.bubbleAuthor}">${contentHtml}</div>
      </div>
    `;
  }

  async show({ scale, duration, easing, stagger }: SequenceScript.TweenProperties) {
    this.style.transformOrigin = this.rightAlign ? '100% 0' : '0 0';
    await this.updateComplete;

    if (!stagger || this.words.length < 2) {
      await gsap.fromTo(
        this,
        {
          scale: scale,
        },
        {
          opacity: 1,
          scale: 1.0,
          duration,
          ease: easing,
        }
      );
    } else {
      const staggerDuration =
        stagger === true || isNaN(+stagger) || stagger <= 0 ? 0.033 : +stagger;
      await gsap.fromTo(
        this.words,
        {
          opacity: 0,
          scale: scale,
        },
        {
          opacity: 1,
          scale: 1.0,
          duration,
          ease: easing,
          stagger: staggerDuration,
        }
      );
    }
  }

  hide({ scale: finalScale, duration, easing }: SequenceScript.TweenProperties) {
    return gsap
      .fromTo(
        this,
        {
          opacity: 1,
          scale: 1.0,
        },
        {
          opacity: 0,
          scale: finalScale,
          duration,
          ease: easing,
        }
      )
      .then(() => this.dispose());
  }

  jump() {
    return gsap.fromTo(this, { y: '100%' }, { y: 0, duration: 0.25, ease: 'power2.out' });
  }

  setHighlightEnabled(enabled: boolean) {
    if (enabled) {
      this.classList.add('highlight');
    } else {
      this.classList.remove('highlight');
    }
  }

  setHardCorners(props: {
    topLeft: boolean;
    topRight: boolean;
    bottomLeft: boolean;
    bottomRight: boolean;
  }) {
    this.bubbleContentDiv!.classList.toggle('hard-corner-tl', props.topLeft);
    this.bubbleContentDiv!.classList.toggle('hard-corner-tr', props.topRight);
    this.bubbleContentDiv!.classList.toggle('hard-corner-bl', props.bottomLeft);
    this.bubbleContentDiv!.classList.toggle('hard-corner-br', props.bottomRight);
  }

  dispose() {
    this.remove();
    this.onDisposeHandlers.forEach(handler => handler());
  }
}
