import { createDelegateFunction } from './core/createDelegateFunction';

export class ObservableValue<T> {
  private value: T;

  public readonly onChange = createDelegateFunction<[newValue: T, oldValue: T]>();

  constructor(value: T) {
    this.value = value;
  }

  public get(): T {
    return this.value;
  }

  public set(newValue: T, force: boolean = false): void {
    const oldValue = this.value;

    if (!force && oldValue === newValue) {
      return;
    }

    this.value = newValue;

    this.onChange.emit(newValue, oldValue);
  }
}
