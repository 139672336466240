import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const TAG_NAME = 'app-version';

@customElement(TAG_NAME)
export class AppVersionIndicator extends LitElement {
  /**
   * The version string to display.
   */
  @property({ type: String })
  version = 'unknown';

  static styles = css`
    :host {
      position: absolute;
      bottom: 0.25em;
      left: 1em;
      z-index: 999999;
      font-size: 10px;
      font-family: monospace;
      color: white;
    }

    .vital-part {
      opacity: 0.25;
      transition: opacity 0.2s;
    }

    .other-part {
      opacity: 0.0;
      transition: opacity 0.2s;
    }

    :host(:hover) .vital-part {
      opacity: 0.5;
    }

    :host(:hover) .other-part {
      opacity: 0.5;
    }
  `;

  render() {
    const [beforeDash, ..._afterDash] = this.version.split('-');
    const afterDash = _afterDash.join('-');
    
    return html`
      <span class="vital-part">${beforeDash}</span><span class="other-part">–${afterDash}</span>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: AppVersionIndicator;
  }
}

export function addAppVersionIndicator(parentElement: HTMLElement, version: string) {
  const appVersion = document.createElement(TAG_NAME);
  appVersion.version = version;
  parentElement.appendChild(appVersion);
}
