// @ts-nocheck

export function disableZoomGestures() {
  document.addEventListener(
    'touchmove',
    function (event) {
      if (event.scale !== undefined && event.scale !== 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );

  document.addEventListener(
    'gesturestart',
    function (event) {
      event.preventDefault();
    },
    { passive: false }
  );

  document.addEventListener(
    'touchstart',
    function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );
}
