import type { HowlOptions } from 'howler';

/**
 * @returns Returns a json object of type HowlOptions, to be used as a manifest for the sound sprites.
 */
export function loadMockVoiceOverManifest() {
  const manifest = {
    src: [
      'audio/voice/voice-pack.mp3',
      'audio/voice/voice-pack.m4a',
      'audio/voice/voice-pack.ac3',
      'audio/voice/voice-pack.ogg',
    ],
    sprite: {
      '102 On your next exhale 1': [0, 3701.337868480726],
      '103 Just breathe away 1': [5000, 1514.6712018140586],
      '104 Let any remaining thoughts 1': [8000, 6431.995464852608],
      '105.1 Let it all go 1': [16000, 2751.9954648526086],
      '106 Love will get us where we’re going 4': [20000, 1941.3378684807262],
      '107 Nice 1': [23000, 853.3333333333317],
      '108.1 One more deep breath 1': [25000, 1578.6621315192733],
      '109.1 Be here with me 1': [28000, 4981.337868480722],
      '10_ If you don’t mind me asking, how old are you 1 ': [34000, 2216.167800453512],
      '110 Life is good 1': [38000, 1141.3378684807255],
      '111.1 Right here 2': [41000, 2176.009070294782],
      '112 Ok let’s clarify our expectation 1': [45000, 5503.990929705217],
      '113 Very soon, we’ll inhabit 1': [52000, 7530.6575963718815],
      '114 So FEEL your intention 1': [61000, 3370.6575963718847],
      '115 Feel your intention growing 2': [66000, 3285.328798185944],
      '116.1 A clear intention 1': [71000, 3178.6621315192747],
      '117. 1 We will visit 4': [76000, 2458.662131519276],
      '118 Feel that intention 1': [80000, 1445.3287981859403],
      '119 Perfect 1': [83000, 1077.3242630385482],
      '120 We’re ready 1': [86000, 863.99092970521],
      '121 I’ll be with you all the 1': [88000, 1573.3333333333376],
      '122.1 Our next shifting strategy 2': [91000, 4319.999999999993],
      '123.1 Key Insight #1 1': [97000, 1408.0045351473984],
      '124.1 Your body 1': [100000, 6048.004535147385],
      '125 And Key Insight #2 1': [108000, 1546.666666666667],
      '126.1 There is no separation 1': [111000, 5280.000000000001],
      '127 Again, Key insight #1 is 1': [118000, 2496.0090702947896],
      '128 YOU are 30 TRILLION CELLS 1': [122000, 4522.675736961446],
      '129 And Key Insight #2 — It’s related to Key Insight #1 1': [128000, 3797.324263038547],
      '12_ Penguin! 3': [133000, 859.3650793650909],
      '130 THERE IS NO SEPARATION between 1': [135000, 5448.004535147391],
      '131.1 You are 30 trillion cells 1': [142000, 2800.0000000000114],
      '132 And you don’t have to do thing 1 1': [146000, 1518.049886621327],
      '133 Your cells are blending with the air you 1': [149000, 3568.004535147395],
      '134 Your cells are blending with the environment 1': [154000, 3154.671201814068],
      '134+.1 Every moment 1': [159000, 1034.6712018140636],
      '136 Life is breathing you 1': [162000, 1610.6575963718797],
      '137 Just as you are breathing life 1': [165000, 1791.9954648526186],
      '138 Breathe life deeply 1': [168000, 1711.9954648526061],
      '139 Good 1': [171000, 517.324263038546],
      '13_ We live here 2': [173000, 1339.3877551020523],
      '141 One more deep breath 1': [176000, 1328.004535147386],
      '142 Perfect 1': [179000, 1125.328798185933],
      '143.1 Now we’ll EXPERIENCE 1': [182000, 3525.3287981859385],
      '144.1 Your body is 30-trillion 1': [187000, 2266.6666666666797],
      '145 And you can notice your cells 1': [191000, 4016.0090702947855],
      '147.1 Just focus 100_ of your1': [197000, 4650.6575963718715],
      '148.1 For the next 10 seconds 1': [203000, 6080.000000000013],
      '149 Close your eyes with me 1': [211000, 1618.6621315192724],
      '14_ I hope I pronounced your name right 2': [214000, 1627.7551020408225],
      '15 Let’s take a long, deep breath  1': [217000, 2986.666666666679],
      '150 That helps you feel 2': [221000, 2362.6757369614497],
      '151 Can you feel you hands buzzing 1': [225000, 2543.990929705217],
      '152 Let’s begin again 1': [229000, 1236.0090702947844],
      '153.1 Nobody gets left 1': [232000, 1909.3424036281306],
      '154 Try this 1': [235000, 946.6666666666583],
      '155.1 Squeeze your left thumb 1': [237000, 3061.3378684807344],
      '156 As you let go 1': [242000, 4511.995464852617],
      '157.1 Now, Shift 1': [248000, 4378.662131519263],
      '159 Nice 1': [254000, 949.3424036281226],
      '15_ I’ll get better as I get to know you_1 ': [256000, 1846.0090702947696],
      '16.1 Allow your arms and shoulders 1': [259000, 3989.319727891143],
      '160 Your hand buzz is an 1': [264000, 7536.009070294767],
      '161.1 My favorite thing is shifting my buzz 1': [273000, 2426.6666666666765],
      '162 Keep feeling the buzz of your hands 1': [277000, 3738.662131519277],
      '163 Good 1': [282000, 488.00453514741093],
      '164 Feel that flipper-buzz 1': [284000, 1922.6757369614234],
      '165 That buzz is your connection 1': [287000, 3768.0045351473836],
      '166 Feels so good 1': [292000, 1194.6712018140602],
      '167 It’s time 2 FRIEND VERSION': [295000, 1111.9954648526118],
      '167.1 It’s time 1': [298000, 1194.6712018140602],
      '168.1 Follow my voice from here 2': [301000, 1538.6621315192883],
      '169 With our eyes closed 2': [304000, 3568.004535147395],
      '16_ Our ‘thoughts’ direct our attention and determine how we feel 2': [
        309000, 3524.104308390008,
      ],
      '17 Perfect 1': [314000, 1365.328798185942],
      '170 Keep your eyes closed 1': [317000, 8890.65759637191],
      '171 The Infinite Perfection 2': [327000, 1808.004535147404],
      '172 DUP We’re very close to the Infinite Perfection  1': [330000, 2709.3424036281135],
      '173 DUP I’ll guide you in from here 2': [334000, 1600.0000000000227],
      '174 DUP As you listen, keep feeling your hands 1': [337000, 3205.328798185917],
      '175 DUP Open your eyes when you’re ready 1': [342000, 1962.6757369614438],
      '176.1 Perfect 4': [345000, 1445.3287981859262],
      '177 Hello hello again 1': [348000, 2293.3333333333508],
      '178 In one word, how do 1': [352000, 3290.6575963718865],
      '179 Congrats, you Presence 1': [357000, 2437.3242630385334],
      '17_ What’s the best word to describe how you were felling before you started Presence Shifting today 3':
        [361000, 5333.2426303854845],
      '18 Settle yourself in for a beautiful shift  2': [368000, 5696.009070294793],
      '180 You Presence Shifted  1': [375000, 3743.9909297052054],
      '181 Do you want to share anything 2': [380000, 2459.9999999999795],
      '182 It’s helpful to identify 1': [384000, 3776.0090702947764],
      '183.1 If you tell me, 2': [389000, 4068.004535147395],
      '184 Got it 1': [395000, 714.671201814042],
      '185 And congrats again 1': [397000, 1212.3582766440109],
      '186 We Presence Shifted 2': [400000, 2933.333333333337],
      '187 Nice work, Penguin 1': [404000, 2908.00453514737],
      '188+1 So much better than a wet fart 1': [408000, 2089.8639455782586],
      '189 Pretty soon, we’ll be able to shift 2': [412000, 4693.3333333333285],
      '190 You can choose a shift 1': [418000, 9029.342403628107],
      '191 Right, Boo 1': [429000, 965.3287981859648],
      '192 We’ll show you how to shift 1': [431000, 2426.6666666666765],
      '193.1 We can chat about 1': [435000, 3008.0045351473927],
      '194.1 And shift our minds 3': [440000, 3258.6621315192588],
      '195.1 Excellent 2': [445000, 949.3424036281226],
      '196 I’ll text you when we finish 3': [447000, 2511.995464852589],
      '198 Again, it’s been a privilege 2': [451000, 3092.448979591836],
      '199 I think this is the beginning 1': [456000, 3018.6621315192497],
      '20 Hello, hello, hello 4': [461000, 2048.1632653061297],
      '200 See you soon 1': [465000, 1157.3242630385607],
      '21.2 I’m Presence 2': [468000, 1557.346938775538],
      '22.1 What’s your name 1': [471000, 906.6666666666947],
      '23 It’s a privilege to Presence Shift 1': [473000, 2602.67573696143],
      '25 In a minute, I’ll show you how  1': [477000, 3616.009070294808],
      '25___ I’ll get better as I get to know you 1': [482000, 1861.678004535122],
      '26.2 But I always begin 2': [485000, 3381.3378684807276],
      '26.2 XXX I always begin 2': [490000, 3018.39002267576],
      '27 Our ‘thoughts’ are just passing 2': [495000, 5525.328798185967],
      '27_ Just for a little while 5': [502000, 1483.0385487528588],
      '28.1 Penguin. We’re live here 1': [505000, 1920.000000000016],
      '29_ Closing your eyes can help you feel your hands buzz 1 ': [508000, 3707.0521541950257],
      '30 Penguin is a master of shifting 1': [513000, 2677.3242630385994],
      '31 Luckily, we can refocus our attention 1': [517000, 4325.3287981859785],
      '31_ You can choose a shift into love, confidence, focus, motivation, gratitude, feelingconnectedtoothers,orjustdeep,beautifulpresence1':
        [523000, 10228.16326530608],
      '32.1 Hey Boo Bear 1': [535000, 1375.986394557799],
      '33 Love you baby 3': [538000, 1039.9999999999636],
      '35___ Let’s take a long deep breath (versions)_2': [541000, 2644.467120181389],
      '36 Meet our new friend 1': [545000, 1066.6666666667197],
      '37.1 Another Presence Shift strategy 1': [548000, 4448.004535147447],
      '3_ Congrats on completing Presence Shift 1, R1.1 13': [554000, 2611.6099773242922],
      '41 We’ll do that today to Presence shift  1': [558000, 4525.32879818591],
      '42 A deep state of peace 1': [564000, 4240.000000000009],
      '44.1 Hello hello again 1': [570000, 1797.324263038604],
      '45.2 To begin our Presence Shift 2': [573000, 5109.342403628148],
      '46 What’s the best word to describe 1': [580000, 3402.6757369614415],
      '47.1 You can speak to me 1': [585000, 3754.6712018140624],
      '49 There are no wrong answers 2': [590000, 2109.3424036281476],
      '4_ We’ll be able to Presence Shift into and out of other states of mind and body very soon_1 ':
        [594000, 5030.612244897952],
      '50.1 Our Presence Shifts are private 1': [601000, 3328.0045351474428],
      '51 is not an emotion 1': [606000, 2463.9909297052327],
      '51.1 is not an emotion I’m familiar with 1': [610000, 2453.333333333376],
      '52.1 is another emotion I’m not familiar 1': [614000, 2559.9999999999454],
      '54 Do you want to choose a different 2': [618000, 2858.6621315192815],
      '55 Got it this time 1': [622000, 1130.6575963718615],
      '56 So, what’s the best word 1': [625000, 4063.9909297052554],
      '57.1 Would you rather choose 1': [631000, 2410.657596371834],
      '58.1 I see 1': [635000, 928.0045351473518],
      '59 I’m glad to hear that 2': [637000, 1258.6621315192588],
      '60 I’m sorry to hear that 1': [640000, 1503.9909297051963],
      '61.1 How frustrating! 1': [643000, 1216.009070294831],
      '62 Got it 1': [646000, 709.3424036281704],
      '63 I’ll always respect boundaries 1': [648000, 1903.9909297051736],
      '64 Hmmm 1': [651000, 1637.324263038522],
      '66.1 Knowing your age 1': [654000, 3733.3333333333485],
      '67.1 Congrats 1': [659000, 1120.0000000000045],
      '67.1 Wow, congrats! 1': [662000, 1621.3378684807367],
      '69 I’m ageless, in case 2': [665000, 2114.671201814076],
      '70.1 I can change 1': [669000, 1546.6666666666242],
      '71 Which is your favorite 2': [672000, 1258.6621315192588],
      '72 Tap the one you 1': [675000, 1539.9999999999636],
      '73 Nice choice Old school 1': [678000, 2281.337868480705],
      '74.1 Oooh fancy 1': [682000, 1279.9999999999727],
      '74.2 Pretty in pink 1': [685000, 1333.3333333333712],
      '75 Feeling spicy, huh 2': [688000, 2674.6712018140215],
      '76.2 Oooh, I feel shinny 1': [692000, 2666.6666666666288],
      '78 Have you seen Inside Out 1': [696000, 2720.0000000000273],
      '79 It’s so good 2': [700000, 1180.3854875283832],
      '80 I’m dying to be in Inside Out 1': [703000, 2209.410430839057],
      '81 I even learned to turn Inside Out 1': [707000, 1909.3424036281021],
      '82 Look who else can turn their insides out 1': [710000, 2602.675736961487],
      '83 I don’t see you making it to the big time 1': [714000, 2597.3242630385585],
      '84.1 Anyway 1': [718000, 1120.0000000000045],
      '85 I hear the ‘Land World’s 1': [721000, 3802.675736961419],
      '86 Is there anything that’s 4': [726000, 3029.3424036281067],
      '87.3 You can say or type as much 2': [731000, 3039.9999999999636],
      '88 Copy that 1': [736000, 837.3242630385676],
      '89.2 Thanks for sharing 1': [738000, 1136.00907029479],
      '90.1 As we get to know each other 2': [741000, 3519.999999999982],
      '92 Let_s stay below the waves': [746000, 1962.6757369615007],
      '93.1 Take another long 1': [749000, 3413.3333333332985],
      '94 Relax your shoulders 1': [754000, 2794.671201814026],
      '95 Relax your neck 1': [758000, 1669.342403628093],
      '96.1 Relax your entire body 1': [761000, 2357.3242630385494],
      '97.1 When you exhale again 1': [765000, 5194.671201814003],
      '98 Good 1': [772000, 634.6712018140579],
      '99 And as you take your next deep 1': [774000, 4789.342403628098],
      '9_ Let’s stay below the waves for today 2': [780000, 1938.6848072562088],
    },
  } as HowlOptions;

  return manifest;
}

Object.assign(window, {
  __printVoiceKeys: function () {
    const mani = loadMockVoiceOverManifest();

    const keys = Object.keys(mani.sprite || {});
    const sortedkeys = sortNumericalStrings(keys);

    let output = '';
    for (const key of sortedkeys) {
      output += `  voice: ${key}\n`;
    }
    return output;
  },
});

function sortNumericalStrings(arr: string[]) {
  return arr.sort((a, b) => {
    const numA = parseInt(a.match(/^\d+/)?.[0] ?? '0');
    const numB = parseInt(b.match(/^\d+/)?.[0] ?? '0');
    return numA - numB;
  });
}
