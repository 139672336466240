import { buildVars } from '@/constants/buildVars';
import { appContext } from '@/context/appContext';
import { ShiftContext } from '@/context/shiftContext';
import { defaultVars } from '@/misc/defaultVars';
import { isIOS } from '@/tools/platform/isIOS';

function createStringyVarsContextBase(shiftContext: ShiftContext, ignoreAnswerDefaults = true) {
  const shifterName = appContext.userData.profile.proxy.name
    ? appContext.userData.profile.proxy.name
    : ignoreAnswerDefaults
      ? null
      : defaultVars.shifter_name;

  const shifterAge =
    appContext.userData.profile.proxy.age === null ? '' : appContext.userData.profile.proxy.age;

  return {
    ...appContext.userData.answers.proxy,
    ...shiftContext.userAnswers.getAllValues(),
    shifter_name: shifterName,
    shifter_age: shifterAge,
    foo: 'bar',
  } as Record<string, string | { [key: string]: string }>;
}

export function createStringyVarsContext(shiftContext: ShiftContext, ignoreAnswerDefaults = true) {
  const activityStringified = Object.fromEntries(
    Object.entries(appContext.userActivityHistory).map(([key, value]) => [key, String(value)])
  );

  return {
    answers: shiftContext.userAnswers.getAllValues(),
    ...createStringyVarsContextBase(shiftContext, ignoreAnswerDefaults),
    activity: activityStringified,
    dev: createDevVars() as Record<string, any>,
    build: buildVars,
  } as Record<string, string | { [key: string]: string }>;
}

export function createFlatStringyVarsContext(
  shiftContext: ShiftContext,
  ignoreAnswerDefaults = true
) {
  function convertToFlat(obj: Record<string, unknown>, prefix: string) {
    if (prefix) prefix += '.';
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [prefix + key, String(value)])
    );
  }

  const activityStringified = convertToFlat(appContext.userActivityHistory, 'activity');
  const userAnswersStringified = convertToFlat(appContext.userData.answers.proxy, 'answers');
  const devStringified = convertToFlat(createDevVars(), 'dev');
  const buildStringified = convertToFlat(buildVars, 'build');

  return {
    ...createStringyVarsContextBase(shiftContext, ignoreAnswerDefaults),
    ...userAnswersStringified,
    ...activityStringified,
    ...devStringified,
    ...buildStringified,
  } as Record<string, string>;
}

function createDevVars() {
  return {
    skipColorChanges: isIOS(),
    isIOS: isIOS(),
    ...appContext.urlParams,
  };
}
