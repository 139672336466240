import { appContext } from "@/context/appContext";
import { isMacOS } from "@/tools/platform/isMacOS";

export type SocialSharePlatform = 'facebook' | 'xwitter' | 'instagram' | 'email' | 'native_share';

export function createSocialSharingService() {
  const isMac = isMacOS();

  return {
    isMac,
    getSupportedPlatforms(): SocialSharePlatform[] {
      if (isMac) {
        return ['facebook', 'xwitter', 'email']; // 'instagram'
      } else {
        return ['facebook', 'xwitter', 'email', 'native_share']; // 'instagram'
      }
    },
    shareOnPlatform,
  };
}

// const urlBase = 'https://tps-script-player.web.app';
const urlBase = 'https://presenceshift.com';

function generateURL(source: SocialSharePlatform) {
  const utm_source = source;
  const utm_medium = 'app-share-screen';
  const utm_campaign = 'mvp1';
  const url = new URL(urlBase);
  url.searchParams.append('utm_source', utm_source);
  url.searchParams.append('utm_medium', utm_medium);
  url.searchParams.append('utm_campaign', utm_campaign);
  return url.toString();
}

async function shareOnPlatform(platform: SocialSharePlatform, shifterName: string) {
  switch (platform) {
    case 'facebook':
      await shareOnFacebook(shifterName);
      break;
    case 'xwitter':
      await shareOnTwitter(shifterName);
      break;
    case 'instagram':
      await shareOnInstagram(shifterName);
      break;
    case 'email':
      await shareViaEmail(shifterName);
      // await sendHtmlEmail(shifterName);
      break;
    case 'native_share':
      shareGeneric(shifterName);
      break;
  }

  appContext.events.socialShare.emit(platform);
}

async function shareGeneric(_shifterName: string) {
  const url = generateURL('native_share');
  const data = {
    title: 'The Presence Shift',
    // text: 'Have you met Presence?',
    url: url,
    text: 'Have you met Presence?\n\n' + url,
    files: [],
  };

  try {
    await navigator.share(data);
  } catch (e) {
    console.log('share error', e);
  }
}

async function shareOnTwitter(_shifterName: string) {
  const imageUrl = urlBase + '/images/about/sean-and-presence.png';

  const appUrl = generateURL('xwitter');
  const text = `Have you met Presence?\n\n${appUrl}`;
  const url = `https://twitter.com/intent/tweet?text=${text}`;
  window.open(url, '_blank');
}

async function shareOnFacebook(_shifterName: string) {
  const appUrl = generateURL('facebook');
  const shareUrl = `https://www.facebook.com/sharer/sharer?u=${encodeURIComponent(appUrl)}&quote=${encodeURIComponent('Have you met Presence?')}`;
  window.open(shareUrl, '_blank');
}

async function shareOnInstagram(_shifterName: string) {
  const appUrl = generateURL('instagram');
  const imageUrl = appUrl + '/images/instagram_share.jpg';
  const caption = `Have you met Presence? ${appUrl}`;

  // Construct a special Instagram link (this varies slightly on different platforms)
  const instagramUrl = `instagram://library?AssetPath=${encodeURIComponent(imageUrl)}&InstagramCaption=${encodeURIComponent(caption)}`;

  // Try opening the Instagram app; fall back to a web-based flow if needed
  try {
    window.location.href = instagramUrl;
  } catch (e) {
    window.location.href = `https://www.instagram.com/?url=${encodeURIComponent(appUrl)}`;
  }
}

async function shareViaEmail(shifterName: string) {
  const appUrl = generateURL('email');
  const subject = encodeURIComponent('Check out The Presence Shift');
  const imageUrl = urlBase + '/images/about/sean-and-presence.png';
  const body = encodeURIComponent(`Hi,

I wanted to share this with you: The Presence Shift.

Have you met Presence?

You can find more details here: ${appUrl}

Best regards,
${shifterName}`);
  const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
  window.open(mailtoLink, '_blank');
}

// async function sendHtmlEmail(shifterName: string) {
//   const subject = 'Check out The Presence Shift';
//   const imageUrl = appUrl + '/images/about/sean-and-presence.png';
//   const body = `
//     <html>
//       <body>
//         <p>Hi,</p>
//         <p>I wanted to share this with you: The Presence Shift.</p>
//         <p>Have you met Presence?</p>
//         <p><img src="${imageUrl}" alt="Presence Image"></p>
//         <p>You can find more details here: <a href="${appUrl}">${appUrl}</a></p>
//         <p>Best regards,<br>${shifterName}</p>
//       </body>
//     </html>`;

//   const emailData = {
//     to: 'recipient@example.com', // Replace with recipient's email
//     subject: subject,
//     html: body,
//   };

//   try {
//     await emailApi.send(emailData); // Replace with actual email API call
//     console.log('Email sent successfully');
//   } catch (e) {
//     console.log('Failed to send email', e);
//   }
// }
