import { ChatBubblesManager } from '@/controllers/ChatBubblesManager';
import { defaultScriptItemProperties } from '@/data/defaultScriptItemProperties';
import { FakeShifterInput } from '@/debug/FakeShifterInput';
import { PlaybackLayoutElements } from '@/html/addPlaybackLayoutElements';
import { displayShifterInputTextField } from '@/html/playback/displayShifterInputTextField';
import { addShifterInputTapToContinue } from '@/html/playback/ShifterInputTapToContinue';
import { addShifterInteractiveZone } from '@/html/playback/ShifterInteractiveZone';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { createDelegateFunctionAsync } from '@/util/core/createDelegateFunctionAsync';
import { MultipleReasons } from '@/util/core/MultipleReasons';

export function createPlayerControls(elements: PlaybackLayoutElements) {
  const { containerForChatBubbles, containerForShifterInput, mainRow, inputRow } = elements;

  const chatBubblesCtrl = new ChatBubblesManager(containerForChatBubbles);

  const mainCtrlSingleton = {
    elements,
    chatBubblesCtrl,

    reasonsToSkipWaitingForUserTap: new MultipleReasons(),
    onUserTapToContinueRequested: createDelegateFunction(),
    onUserTapToContinueReceived: createDelegateFunction(),

    reasonsToSkipWaitingForUserInput: new MultipleReasons(),
    onUserInputRequested: createDelegateFunction<[inputKey: string]>(),
    onUserInputReceived: createDelegateFunction<[inputKey: string, inputValue: string]>(),

    async waitUntilUserTap() {
      if (this.reasonsToSkipWaitingForUserTap.hasAny()) {
        console.log('⛔ Skipping waiting for user tap...');
        return Promise.resolve();
      }

      console.log('🎤 Waiting for user tap...');

      const customAwaitable = createCustomAwaitable();

      const onFinished = createDelegateFunctionAsync();
      const finish = async () => {
        await onFinished();
        onFinished.clear();
        customAwaitable.resolve();
      };

      {
        //// Add "Tap to Continue" text to the screen
        const ttcElement = addShifterInputTapToContinue(containerForShifterInput);
        await ttcElement.updateComplete;
        onFinished.once(() => ttcElement.removeWithAnimation(0.4));
      }

      {
        //// Add tappable elements
        const tappables = [addShifterInteractiveZone(mainRow), addShifterInteractiveZone(inputRow)];
        onFinished.once(() => tappables.forEach(el => el.remove()));

        //// Handle tap events
        const tapHandler = (evt: Event | PointerEvent) => {
          if (!(evt instanceof PointerEvent)) {
            console.log('🎤 Ignoring non-pointer event');
            return;
          }

          if (evt.pointerType === 'mouse') {
            if (evt.type === 'pointerdown' && evt.buttons !== 1) {
              console.log('🎤 Ignoring mouse tap event. Buttons', evt.buttons);
              return;
            }
          }

          finish();
        };

        tappables.forEach(el => el.addEventListener('pointerdown', tapHandler));
        onFinished.once(() =>
          tappables.forEach(el => el.removeEventListener('pointerdown', tapHandler))
        );

        const ffTouchZone = elements.fastforwardingHitArea;
        ffTouchZone.addEventListener('click', tapHandler);
        onFinished.once(() => ffTouchZone.removeEventListener('click', tapHandler));
      }

      {
        //// Handle reasonsToSkipWaitingForUserTap change
        const off = this.reasonsToSkipWaitingForUserTap.on({ nonEmpty: () => finish() });
        onFinished.once(off);
      }

      await customAwaitable;
    },

    async waitUntilUserAnswer(answerKey: string, prefilledValue?: string) {
      const getShifterWrittenInput = async () => {
        if (this.reasonsToSkipWaitingForUserInput.hasAny()) {
          return FakeShifterInput.getFakeWrittenInput(answerKey);
        }

        return await displayShifterInputTextField(containerForShifterInput, prefilledValue);
      };

      console.log('🧔 Waiting for user input...');

      this.onUserInputRequested.emit(answerKey);

      const result = await getShifterWrittenInput();
      
      this.onUserInputReceived.emit(answerKey, result);

      return result;
    },

    async submitUserAnswer(_answerKey: string, answerValue: string) {
      const userMessageText = answerValue === '' ? '...' : answerValue;
      const bubble = this.addChatItem({
        message: userMessageText,
        author: ChatBubblesManager.shifterAuthorValue,
      });
      await bubble.updateComplete;
      await bubble.show(defaultScriptItemProperties.in);
    },

    addChatItem(item: SequenceScriptItem & { message: string; author?: string }) {
      const bubble = chatBubblesCtrl.addBubble({
        message: item.message,
        className: item.messageStyle ?? 'default-bubble',
        author: item.author ?? '',
      });

      return bubble;
    },

    clearChatItems() {
      return chatBubblesCtrl.clearAllBubbles();
    },
  };

  return mainCtrlSingleton;
}

export type PlayerControls = ReturnType<typeof createPlayerControls>;
