export function captureUTMParams(onParamsCaptured?: (key: string, value: string) => void) {
  const urlParams = new URLSearchParams(window.location.search);

  let capturedAny = false;
  urlParams.forEach((value, key) => {
    if (!key.startsWith('utm_')) return;

    localStorage.setItem(key, value);
    urlParams.delete(key);
    onParamsCaptured?.(key, value);

    capturedAny = true;
  });

  if (!capturedAny) return;

  // Reconstruct URL with non-UTM params
  const newSearch = urlParams.toString();
  const newUrl =
    window.location.pathname + (newSearch ? `?${newSearch}` : '') + window.location.hash;
  window.history.replaceState({}, document.title, newUrl);
}
