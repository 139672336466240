import gsap from 'gsap';

import NoSleep from '@uriopass/nosleep.js';
import { onAuthStateChanged } from 'firebase/auth';

import { MusicService } from '@/audio/music';
import { SoundService } from '@/audio/sfx';
import { VoiceoverService } from '@/audio/voice';
import { createFirestoreUserDataService } from '@/debug/createFirestoreUserDataService';
import { loadMockVoiceOverManifest } from '@/mock/loadMockVoiceOverManifest';
import { createFastForwardingControl } from '@/services/createFastForwardingControl';
import { createTextToSpeechService } from '@/services/createTextToSpeechService';
import { createUserEmotionProcessor } from '@/tools/createUserEmotionProcessor';
import { SmartNumber } from '@/util/core/SmartNumber';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { createLocalStorageDataObjectProxy } from '@/util/core/createLocalStorageDataObjectProxy';
import { createUrlParamsProxy } from '@/util/core/createUrlParamsProxy';
import { createOrientationTracker } from '@/util/createOrientationTracker';

import { auth } from '@/backend/firebase';
import { SocialSharePlatform } from '@/services/createSocialSharingService';
import { enableCopyingDataOnAuthStateChanged } from '@/tools/enableCopyingDataOnAuthStateChanged';
import { enableTrackingViaGoogleTag } from '@/tools/enableTrackingViaGTAG';
import type { ShiftContext } from './shiftContext';

/**
 * Creates the application context object.
 * The context object contains various services and configurations used throughout the application.
 *
 * @returns The application context object.
 */
function createAppContext() {
  const context = {
    appDiv: document.querySelector<HTMLDivElement>('#app')!,

    userData: {
      authUserId: null as string | null,
      profile: createFirestoreUserDataService('UserProfiles', () => ({
        name: null as string | null,
        age: null as number | null,
        phone: null as string | null,
        email: null as string | null,
      })),
      purchases: createFirestoreUserDataService('UserPurchases', () => ({
        accessPS1: false,
        accessBeta: false,
        fiftyCent: false, // $3.00
      })),
      answers: createFirestoreUserDataService(
        'UserAnswers',
        () => ({}) as Record<string, string | null>
      ),
      shifts: createFirestoreUserDataService('UserShifts', () => ({ shifts: [] as any[] })),
      general: createFirestoreUserDataService('Users', () => ({})),
    },

    userPrefs: createLocalStorageDataObjectProxy('userPreferences', {
      autoAdvanceScript: true,
      fastforwardSpeed: 7,
    }),

    userActivityHistory: createLocalStorageDataObjectProxy('userActivity', {
      interactedWithMicrophone: true,
    }),

    urlParams: createUrlParamsProxy({
      password: undefined as string | undefined,
      queue: undefined as string | undefined, // 'intro,ps1'
      fullscreen: false as boolean,
      timeScale: 1 as number,
      localAssets: false,
      localScripts: false,
      orbital: false,
      debug: false,
      debugState: false,
      dev: false,
      sandbox: false as boolean | string,
      ff: false,
      ffTo: undefined as number | string | undefined,
      ffSpeed: undefined as number | undefined,
      skipTo: undefined as number | string | undefined,
      skipSplash: false,
      skipVideo: false,
      skipScript: false,
      skipManual: false,
      mute: undefined as boolean | undefined,
      lowRes: 1 as number,
      clearLocalData: false,
      logOut: false,
      payFiftyCentsOnly: false,
    }),

    config: {
      get autoAdvance() {
        return context.userPrefs.autoAdvanceScript;
      },
      get fastforwardSpeed() {
        return context.userPrefs.fastforwardSpeed;
      },
    },

    animCtrl: {
      timeScale: new SmartNumber(1),
      hookUpToTimeScale(updateCallback: (newValue: number) => void) {
        updateCallback(this.timeScale.get());
        this.timeScale.onChange.on(updateCallback);
      },
    },

    fastforwarder: createFastForwardingControl(),

    emotionsProcessor: createUserEmotionProcessor(),

    sfx: new SoundService('/audio', ['mp3']),
    voice: new VoiceoverService(loadMockVoiceOverManifest()),
    music: new MusicService(),
    aiVoice: createTextToSpeechService(),

    noSleep: new NoSleep(),
    orientationTracker: createOrientationTracker(),

    setMuted(shouldMute: boolean) {
      context.sfx.setMuted(shouldMute);
      context.music.setMuted(shouldMute);
      context.voice.setMuted(shouldMute);
      context.aiVoice.setMuted(shouldMute);
    },

    events: {
      shiftStarted: createDelegateFunction<[shiftContext: DietShiftContext]>(),
      shiftFinished: createDelegateFunction<[shiftContext: DietShiftContext]>(),
      socialShare: createDelegateFunction<[platform: SocialSharePlatform]>(),
      purchaseCompleted: createDelegateFunction<[itemKey: string]>(),
      displaySplashScreen: createDelegateFunction<[splashScreenSlug: string]>(),
      userConversionCheckpoint: createDelegateFunction<[checkpoint: string]>(),
    },
  };

  type DietShiftContext = {
    scriptSlug: ShiftContext['scriptSlug'];
    sceneContext: ShiftContext['sceneContext'];
    gltfs: ShiftContext['gltfs'];
    playerCtrl: ShiftContext['playerCtrl'];
    userAnswers: ShiftContext['userAnswers'];
    events: ShiftContext['events'];
  };

  ////
  //// Dev thigs
  ////
  {
    context.animCtrl.timeScale.addMultiplier(context.urlParams.timeScale);

    if (context.urlParams.clearLocalData) {
      localStorage.clear();
      sessionStorage.clear();
      indexedDB.deleteDatabase('indexedAssets');
    }

    if (context.urlParams.logOut) {
      context.userData.authUserId = null;
      auth.signOut().then(() => console.log('User signed out successfully.'));
    }
  }

  ////
  //// Auth things
  ////

  onAuthStateChanged(auth, user => {
    context.userData.authUserId = user?.uid ?? null;

    if (!user) return;

    gtag('set', { user_id: user?.uid });
  });

  ////
  //// Hook up GSAP timeScale to the context.animationsCtrl.timeScale SmartNumber
  ////
  {
    context.animCtrl.hookUpToTimeScale(value => gsap.globalTimeline.timeScale(value));

    /**
     * Lag smoothing is used to help keep animations smooth even when the frame rate drops.
     *
     * In this case, GSAP is set to step in and correct the animation time whenever the lag
     * is more than 100 milliseconds. During these correction phases, GSAP will assume
     * the lag is 33 milliseconds.
     */
    gsap.ticker.lagSmoothing(100, 33);
  }

  ////
  //// Mute SFX on high time scale
  ////
  {
    const TIMESCALE_TO_SKIP_AUDIO_ABOVE = 2;
    const REASON_TO_SKIP_AUDIO = 'time-scale-too-high';

    context.animCtrl.timeScale.onChange.on(newValue => {
      console.log('🎞 Time scale changed:', newValue);

      const shouldSkipAudio = newValue > TIMESCALE_TO_SKIP_AUDIO_ABOVE;
      context.sfx.reasonsToMute.set(REASON_TO_SKIP_AUDIO, shouldSkipAudio);
      context.voice.reasonsToMute.set(REASON_TO_SKIP_AUDIO, shouldSkipAudio);
      context.aiVoice.reasonsToMute.set(REASON_TO_SKIP_AUDIO, shouldSkipAudio);
    });
  }

  ////
  //// Stop GSAP ticker when the app is not visible
  ////
  {
    const { appDiv } = context;
    appDiv.addEventListener('visibilitychange', () => {
      if (appDiv.hidden) {
        gsap.ticker.sleep();
      } else {
        gsap.ticker.wake();
      }
    });
  }

  {
    const { fastforwarder } = context;
    fastforwarder.isEnabled.set(true);
  }

  {
    context.emotionsProcessor.load();
  }

  // context.appDiv.classList.add('sl-theme-dark');

  {
    //// Enable copying user data from anonymous user to logged in user
    enableCopyingDataOnAuthStateChanged();
  }

  return context as Readonly<typeof context>;
}

export const appContext = createAppContext();

//// Enable tracking via Google Analytics
enableTrackingViaGoogleTag();
