import { db } from '@/backend/firebase';
import { appContext } from '@/context/appContext';
import { ShiftContext } from '@/context/shiftContext';
import { addDoc, arrayUnion, collection, doc, setDoc, updateDoc } from 'firebase/firestore';

export function trackUserShiftsViaFirestore(shiftContext: ShiftContext) {
  shiftContext.events.playScriptStarted.once(async () => {
    let startedAt = new Date();

    const userId = appContext.userData.authUserId!;

    const shiftsCollectionRef = collection(db, 'LogShifts');
    const shiftRef = await addDoc(shiftsCollectionRef, {
      shifter: userId,
      shiftSlug: shiftContext.scriptSlug,
      shiftName: shiftContext.scriptSlug,
      startedAt: startedAt,
      finished: false,
      answers: {},
      deviceInfo: {
        userAgent: navigator.userAgent,
        platform: navigator.platform || null,
        language: navigator.language,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      },
    });

    // Add the new shift's ID to the user's shifts array
    const userRef = doc(db, 'UserShifts', userId);
    setDoc(userRef, { shifts: arrayUnion(shiftRef.id) }, { merge: true });

    shiftContext.events.playScriptStarted.on(script => {
      updateDoc(shiftRef, {
        totalScriptItems: script?.length,
      });
    });

    shiftContext.events.playScriptItemStarted.on((_, scriptItemIndex) => {
      updateDoc(shiftRef, { lastScriptItemStarted: scriptItemIndex });
    });

    shiftContext.events.playScriptItemFinished.on((_, scriptItemIndex) => {
      updateDoc(shiftRef, { lastScriptItemFinished: scriptItemIndex });
    });

    shiftContext.events.playScriptFinished.on(() => {
      updateDoc(shiftRef, {
        finished: true,
        finishedAt: new Date(),
        duration: new Date().getTime() - startedAt.getTime(),
      });
    });
  });
}
