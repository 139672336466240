import { LitElement, css, html } from 'lit';

import gsap from 'gsap';

const TAG_NAME = 'loading-splash';

export function addLoadingSplash(parentElement: HTMLElement) {
  const indicator = document.createElement(TAG_NAME);
  // indicator.id = 'loading-splash';
  parentElement.appendChild(indicator);

  return async function remove() {
    console.log('📝 Removing loading splash');
    await gsap.to(indicator, { duration: 0.3, opacity: 0 });
    parentElement.removeChild(indicator);
  };
}

class LoadingSplash extends LitElement {
  static styles = css`
    :host {
      --spinner-border: 4px;
      --spinner-diameter: 120px;

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      
      place-items: center;
      // min-width: 320px;
      // min-height: 100dvh;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      font-family: 'Roboto', sans-serif;

      animation: popIn 0.5s ease-in-out;
    }

    .spinner {
      border: var(--spinner-border) solid #26264d;
      border-radius: 50%;
      border-top: var(--spinner-border) solid #3498db;
      width: var(--spinner-diameter);
      height: var(--spinner-diameter);

      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 1.2s linear infinite;

      box-shadow:
        0 0 20px #26264d,
        0 0 80px #26264d,
        inset 0 0 10px #26264d,
        inset 0 0 40px #26264d;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes popIn {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  `;

  render() {
    return html`<div class="spinner"></div>`;
  }
}

customElements.define(TAG_NAME, LoadingSplash);
