import { appContext } from '@/context/appContext';
import { createSpeechToTextService } from '@/services/createSpeechToTextService';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createDelegateFunctionAsync } from '@/util/core/createDelegateFunctionAsync';
import { delay } from '@/util/delay';
import { ShifterInputTextField } from './ShifterInputTextField';
import { isIOS } from '@/tools/platform/isIOS';

export async function displayShifterInputTextField(
  parentElement: Element,
  prefilledValue: string | undefined
) {
  const customAwaitable = createCustomAwaitable<string>();
  const onFinished = createDelegateFunctionAsync<[result: string]>();
  const finish = async (result: string) => {
    await onFinished.emit(result);
    onFinished.clear();
    customAwaitable.resolve(result);
  };

  const unblockFF = appContext.fastforwarder.reasonsToPreventFastForwarding.add(
    'display-shifter-input-text-field'
  );
  onFinished.on(unblockFF);

  const sttService = createSpeechToTextService();

  const shifterInputBox = new ShifterInputTextField();
  parentElement.appendChild(shifterInputBox);

  await shifterInputBox.updateComplete;

  const { microphoneButton, submitButton, textInputField } = shifterInputBox;

  if (isIOS()) {
    microphoneButton.style.display = 'none';
  }

  textInputField.value = prefilledValue ?? '';
  textInputField.focus();

  microphoneButton.hidden = false;
  submitButton.hidden = false;
  textInputField.hidden = false;

  const handleSubmit = async () => {
    const result = textInputField.value;

    submitButton.hidden = true;
    textInputField.value = '';

    await shifterInputBox.removeWithAnimation(0.4);

    finish(result);
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  submitButton.addEventListener('click', handleSubmit);
  onFinished.on(() => microphoneButton.removeEventListener('click', handleSubmit));

  textInputField.addEventListener('keydown', handleEnter);
  onFinished.on(() => textInputField.removeEventListener('keydown', handleEnter));

  //// Speech to Text

  if (!sttService.isSupported) {
    microphoneButton.remove();
  } else {
    const onMicrophoneClick = () => {
      const onFinished = createDelegateFunctionAsync();
      const finish = async () => {
        await onFinished.emitAndClear();
      };

      shifterInputBox.setListeningMode(true);
      onFinished.once(() => shifterInputBox.setListeningMode(false));

      microphoneButton.hidden = true;
      submitButton.hidden = true;
      textInputField.hidden = true;
      onFinished.once(() => {
        microphoneButton.hidden = false;
        submitButton.hidden = false;
        textInputField.hidden = false;
      });

      textInputField.value = '';

      const stt = sttService.startSpeechToText();
      if (!stt) {
        console.error('🎤 Speech recognition not supported.');
        finish();
        return;
      }

      stt
        .then(text => {
          textInputField.value = text;
          appContext.userActivityHistory.interactedWithMicrophone = true;
        })
        .catch(error => {
          console.error('🎤 Failed to get speech:', error);
        })
        .finally(() => {
          console.log('🎤 Speech recognition finished.');
          finish();
        });

      delay(7).then(() => {
        if (stt.isSettled()) return;
        stt.abort();
        finish();
      });

      const onClick = () => {
        if (stt.isSettled()) return;
        console.log('🎤 User clicked');
        stt.stop();
        finish();
        window.removeEventListener('click', onClick);
      };
      delay(0.7).then(() => window.addEventListener('click', onClick));
    };

    microphoneButton.addEventListener('click', onMicrophoneClick);
    onFinished.on(() => microphoneButton.removeEventListener('click', onMicrophoneClick));
  }

  return await customAwaitable;
}
