import { addFastForwardingIndicator } from '@/html/addFastForwardingIndicator';
import { PlaybackLayoutElements } from '@/html/addPlaybackLayoutElements';
import { createPlayerControls } from '@/scene/createPlayerControls';
import { UserAnswersStore } from '@/services/UserAnswersStore';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { appContext } from './appContext';
import { SceneContext } from './sceneContext';
import { AsortedConstants } from '@/constants/asorted';
import { trackUserShiftsViaFirestore } from '@/tools/trackUserShiftsViaFirestore';

export function createShiftContext(
  sceneContext: SceneContext | null,
  elements: PlaybackLayoutElements,
  scriptSlug: string
) {
  const onDispose = createDelegateFunction();

  const context = {
    scriptSlug: scriptSlug,

    sceneContext,

    gltfs: sceneContext?.gltfs,

    playerCtrl: createPlayerControls(elements),

    events: {
      playScriptStarted: createDelegateFunction<[script: SequenceScript]>(),
      playScriptItemStarted:
        createDelegateFunction<[scriptItem: SequenceScriptItem, scriptItemIndex: number]>(),
      playScriptItemFinished:
        createDelegateFunction<[scriptItem: SequenceScriptItem, scriptItemIndex: number]>(),
      playScriptFinished: createDelegateFunction<[script: SequenceScript]>(),
    },

    userAnswers: new UserAnswersStore(),

    dispose() {
      onDispose();

      context.events.playScriptStarted.clear();
      context.events.playScriptItemStarted.clear();
      context.events.playScriptItemFinished.clear();
      context.events.playScriptFinished.clear();
    },
  };

  //// DEBUG THINGS
  {
    context.userAnswers.onValueChange.on((key, value) => {
      console.log('📝 User answer changed:', key, value);
      appContext.userData.answers.setFieldValue(key, value);
    });
  }

  //// SYNC ANSWERS WITH FIRESTORE
  {
    // appContext.userData.answers.getUserData().then(userData => {
    //   for (const key in userData) {
    //     context.userAnswers.setValue(key, userData[key]);
    //   }
    // });
  }

  //// FF
  {
    const { fastforwarder } = appContext;
    const { playerCtrl } = context;

    const fastForwardingSpeed =
      appContext.urlParams.ffSpeed ?? AsortedConstants.FAST_FORWARDING_SPEED;
    const timeScaleMultiplier = appContext.animCtrl.timeScale.addMultiplier(1);
    onDispose.on(() => appContext.animCtrl.timeScale.removeModifier(timeScaleMultiplier));

    const offTTC = fastforwarder.isFastForwarding.onChange.on(isFastForwarding => {
      timeScaleMultiplier.set(isFastForwarding ? fastForwardingSpeed : 1);

      const REASON_TO_SKIP_TTC = 'fast-forwarding-in-progress';
      playerCtrl.reasonsToSkipWaitingForUserTap.set(REASON_TO_SKIP_TTC, isFastForwarding);

      if (isFastForwarding) {
        const removeIndicator = addFastForwardingIndicator(document.body);
        const stopListening = fastforwarder.isFastForwarding.onChange.on(() => {
          stopListening();
          removeIndicator();
        });
      }
    });
    onDispose.on(offTTC);

    const offInput = playerCtrl.onUserInputRequested.on(() => {
      const REASON_TO_DISABLE_FF = 'waiting-for-user-input';

      fastforwarder.reasonsToPreventFastForwarding.add(REASON_TO_DISABLE_FF);

      playerCtrl.onUserInputReceived.once(() => {
        fastforwarder.reasonsToPreventFastForwarding.remove(REASON_TO_DISABLE_FF);
      });
    });

    onDispose.on(offInput);
  }

  trackUserShiftsViaFirestore(context);

  return Object.freeze(context);
}

export function disposeShiftContext(context: ShiftContext) {
  for (const value of Object.values(context.events)) {
    value.clear();
  }
}

export type ShiftContext = ReturnType<typeof createShiftContext>;
