import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { MultipleReasons } from '@/util/core/MultipleReasons';
import { Howl } from 'howler';

const REGULAR_MUTE_REASON = 'manually-muted';

export class SoundService {
  public readonly reasonsToMute = new MultipleReasons();

  private _currentSounds: { sound: Howl; resolve: () => void }[] = [];

  constructor(
    public readonly baseUrl: string,
    public readonly extensions: string[]
  ) {
    this.reasonsToMute.on({
      nonEmpty: () => this.stopAllSounds(),
    });
  }

  setMuted(shouldMute: boolean) {
    if (this.isMuted === shouldMute) {
      return;
    }

    this.reasonsToMute.set(REGULAR_MUTE_REASON, shouldMute);
  }

  get isMuted() {
    return this.reasonsToMute.has(REGULAR_MUTE_REASON);
  }

  playSoundBite(fileName: string, extensions = this.extensions) {
    const awaitable = createCustomAwaitable();

    if (this.reasonsToMute.hasAny()) {
      awaitable.resolve();
      return Object.assign(awaitable, { soundInstance: null, playHandle: null });
    }

    const sourceUrls =
      fileName.indexOf('.') > -1
        ? [`${this.baseUrl}/${fileName}`] // If the file name already has an extension, use it as is
        : extensions.map(ext => `${this.baseUrl}/${fileName}.${ext}`);

    console.log('🎤 Playing sound:', sourceUrls);

    const soundInstance = new Howl({
      src: sourceUrls,
      onend: () => {
        this._currentSounds = this._currentSounds.filter(s => s.sound !== soundInstance);
        awaitable.resolve();
      },
      onloaderror: () => {
        console.error('🎤 Failed to load sound:', sourceUrls);
        awaitable.resolve();
      },
    });

    this._currentSounds.push({ sound: soundInstance, resolve: awaitable.resolve });
    const playHandle = soundInstance.play();

    return Object.assign(awaitable, { soundInstance, playHandle });
  }

  playSoundBiteTrackable(fileName: string, extensions = this.extensions) {
    const awaitable = this.playSoundBite(fileName, extensions);

    if ('soundInstance' in awaitable && awaitable.soundInstance) {
      const onDone = createDelegateFunction();

      const { soundInstance, playHandle } = awaitable;

      soundInstance.on('end', onDone);

      return Object.assign(awaitable, {
        callAtTime(atTime: number, callback: () => void) {
          let handle = null as any;

          function cleanUp() {
            cancelAnimationFrame(handle);
          }
          onDone.on(() => cleanUp());

          function onFrame() {
            const sndTime = soundInstance.seek();

            if (sndTime >= atTime) {
              callback();
              cleanUp();
              return;
            }

            handle = requestAnimationFrame(onFrame);
          }

          onFrame();
        },
        skipForward(seconds: number) {
          const currentTime = soundInstance.seek(playHandle);
          soundInstance.seek(currentTime + seconds, playHandle);
          return soundInstance.seek(playHandle);
        },
        skipTo(seconds: number) {
          return soundInstance.seek(seconds, playHandle);
        },
      });
    } else {
      return Object.assign(awaitable, {
        callAtTime: null,
        skipForward: null,
        skipTo: null,
      });
    }
  }

  stopAllSounds(): void {
    this._currentSounds.forEach(({ sound, resolve }) => {
      sound.stop();
      resolve();
    });
    this._currentSounds = [];
  }
}
