type BuildVars = Record<string, string | undefined> & {
  passwordBase64: string;
  appPackageVersion: string;
  appBuildVersion: string;
  scripts: string;
  gates: {
    signIn: boolean;
    shareForPS1: boolean;
    subForPS2: boolean;
    learnModeEndScr: boolean;
  };
  requireSignInBefore: string | undefined;

  showSubscribeForBetaPaymentLink: boolean | undefined;
  showSubstackLink: boolean | undefined;
  substackLinkUrl: string | undefined;
  showShareButtonsOnLearnMore: boolean | undefined;

  openaiApiKey: string | undefined;
  elevenLabsApiKey: string | undefined;
  elevenLabsVoiceId: string | undefined;
};

export const buildVars = process.env as typeof process.env & BuildVars;
